import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { withFormik, Field, getIn } from 'formik';
import * as Yup from 'yup';
import snakeCaseKeys from 'snakecase-keys';
import camelCaseRecursive from 'camelcase-keys-recursive';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUndo } from '@fortawesome/free-solid-svg-icons';
import { genericErrorMessage } from '../../services/apiService';
import { updateDeliveryOrder, findOrCreateDeliveryOrderRequest } from '../../requests/deliveryOrders';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../requests/dropzone';
import { CustomTooltip, LoadSpinner, Dropzone, FormikInput, FormikSelect } from '../../components';
import { formatMessage } from '../../i18n/language';
import { baseOptions, excludingBaseOptions } from './status';

const HelpTooltip = () => (
  <div>
    <p>
      <b>{formatMessage({ id: 'delivery.tooltip.generate' })}</b>
      {formatMessage({ id: 'delivery.tooltip.generateHelp' })}
    </p>
    <p>
      <b>{formatMessage({ id: 'delivery.tooltip.doNotGenerate' })}</b>
      {formatMessage({ id: 'delivery.tooltip.doNotGenerateHelp' })}
    </p>
    <p>
      <b>{formatMessage({ id: 'delivery.tooltip.associate' })}</b>
      {formatMessage({ id: 'delivery.tooltip.associateHelp' })}
    </p>
  </div>
);

const DeliveryRequestEdit = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [deliveryRequestExists, setDeliveryRequestExists] = useState(false);
  const [deliveryRequest, setDeliveryRequest] = useState({});
  const [formattedDropZoneFiles, setFormattedDropZoneFiles] = useState([]);

  const {
    match: {
      params: { id: deliveryRequestId }
    },
    location: { search }
  } = props;

  const { jwt, fjwt } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  const { isValid, handleSubmit, errors, touched, setFieldValue, values } = props;
  const { id: backId, documentReferences = [] } = deliveryRequest;

  const handleSuccessGetDeliveryRequest = response => {
    setOnRequest(false);
    const responseDeliveryRequest = camelCaseRecursive(response.data);
    setDeliveryRequest(responseDeliveryRequest);
    setDeliveryRequestExists(true);
    setFormattedDropZoneFiles(responseDeliveryRequest.dropZoneFiles.map(dzf => dzf.document));
  };

  const handleDropzoneDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'delivery_review_image',
        documents,
        delivery_order_id: backId
      }
    };

    return createDropZoneFileRequest({
      dispatch,
      formData: true,
      params: dropzoneFiles,
      successCallback: r => r
    });
  };

  const handleDeleteDropZone = deleteFile => {
    setFieldValue(
      'documentKeys',
      values.documentKeys.filter(code => code !== deleteFile.code)
    );
    deleteDropZoneFileRequest(deleteFile.id, {
      dispatch,
      params: { dropZoneFile: { documentKey: deleteFile.code } }
    });
  };

  const handleOnDeletePersistedFiles = document => {
    return deleteDropZoneFileRequest(document.id, {
      dispatch,
      params: { dropZoneFile: { documentKey: document.code } },
      successCallback: setFormattedDropZoneFiles(formattedDropZoneFiles.filter(dzf => dzf.id !== document.id))
    });
  };

  const handleDropzoneUploaded = files => {
    setFieldValue(
      'documentKeys',
      files.map(file => file.code)
    );
  };

  const handleGetDeliveryRequest = () => {
    setOnRequest(true);
    findOrCreateDeliveryOrderRequest(deliveryRequestId, {
      dispatch,
      formData: true,
      successCallback: handleSuccessGetDeliveryRequest,
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const handlePauseOrder = () => {
    setOnRequest(true);
    updateDeliveryOrder(deliveryRequestId, {
      dispatch,
      params: snakeCaseKeys({ delivery: { status: 0 } }),
      formData: true,
      successCallback: () => {
        setOnRequest(false);
        history.push(`/delivery_requests_list?jwt=${encodeURIComponent(jwt)}&fjwt=${encodeURIComponent(fjwt)}`);
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const handleDeliveryFailure = () => {
    setFieldValue('status', 4);
    handleSubmit();
  };

  const handleDeliverySuccess = () => {
    setFieldValue('status', 2);
    handleSubmit();
  };

  useEffect(() => {
    handleGetDeliveryRequest();
  }, []);

  const refs = documentReferences.map(docRef => {
    const label = `${formatMessage({ id: 'delivery.associate' })} - ${
      docRef.invoiceInitial ? docRef.invoiceInitial : ''
    } ${docRef.waybill} - auto`.trim();
    return {
      value: docRef.waybill,
      label: (
        <>
          {label} <FontAwesomeIcon icon={faUndo} />{' '}
        </>
      )
    };
  });

  const selectOptions = deliveryRequest.project ? baseOptions : excludingBaseOptions;

  const options = [...selectOptions, ...refs];
  const canRender = !onRequest && deliveryRequestExists;

  return (
    <Col md={12} className="card-form delivery-request-index">
      {canRender ? (
        <Form>
          <Row>
            <Col md={4} className="px-0 px-md-2">
              <div className="form-group">
                <p style={{ display: 'inline-block', marginBottom: '0.5rem' }}>
                  {formatMessage({ id: 'delivery.images' })}
                </p>
                <Dropzone
                  persistedFiles={formattedDropZoneFiles}
                  onDrop={handleDropzoneDrop}
                  onDropUploaded={handleDropzoneUploaded}
                  onDelete={handleDeleteDropZone}
                  onDeletePersistedFiles={handleOnDeletePersistedFiles}
                />
              </div>
            </Col>
            <Col md={4} className="px-0">
              <Field name="description">
                {({ field }) => (
                  <FormikInput
                    {...field}
                    label={formatMessage({ id: 'delivery.comments' })}
                    placeholder={formatMessage({ id: 'delivery.commentPlaceholder' })}
                    error={errors[field.name]}
                    touched={touched[field.name]}
                    inputAs="textarea"
                    rows={5}
                    margin="px-0 px-md-2"
                  />
                )}
              </Field>
            </Col>
            <Col md={4} className="px-0">
              <Field name="document">
                {({ field }) => (
                  <FormikSelect
                    {...field}
                    abbr
                    options={options}
                    label={formatMessage({ id: 'delivery.references' })}
                    placeholder={formatMessage({ id: 'delivery.pendingPlaceholder' })}
                    defaultValue="released"
                    onChange={data => setFieldValue(field.name, data.value)}
                    error={getIn(errors, field.name)}
                    touched={getIn(touched, field.name)}
                    margin="px-0 px-md-2"
                  />
                )}
              </Field>
              <CustomTooltip direction="bottom" text={<HelpTooltip />}>
                <div style={{ position: 'absolute', top: '0.2rem', right: '1rem' }}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{}} />
                </div>
              </CustomTooltip>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 3, offset: 3 }}>
              <Button variant="warning" size="lg" className="mt-4 text-uppercase" block onClick={handlePauseOrder}>
                {formatMessage({ id: 'delivery.pause' })}
              </Button>
            </Col>
            <Col md={3}>
              <Button
                variant="outline-danger"
                size="lg"
                className="mt-4 text-uppercase"
                block
                disabled={!isValid}
                onClick={handleDeliveryFailure}
              >
                {formatMessage({ id: 'delivery.failed' })}
              </Button>
            </Col>
            <Col md={3}>
              <Button
                variant="secoundary"
                size="lg"
                className="mt-4 text-uppercase"
                block
                disabled={!isValid}
                onClick={handleDeliverySuccess}
              >
                {formatMessage({ id: 'delivery.success' })}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <LoadSpinner />
      )}
    </Col>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest } = state.auth;
  return {
    ongoingRequest
  };
};

const initialValues = {
  status: '0',
  images: '',
  comments: ''
};

const validationSchema = Yup.object().shape({
  document: Yup.string().required(formatMessage({ id: 'formik.requiredField' }))
});

const handleSubmit = (values, { props }) => {
  const {
    dispatch,
    history,
    match: {
      params: { id: deliveryRequestId }
    },
    location: { search }
  } = props;

  const { jwt, fjwt } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  updateDeliveryOrder(deliveryRequestId, {
    dispatch,
    params: snakeCaseKeys({ delivery: { ...values } }),
    formData: true,
    successCallback: () => {
      history.push(`/delivery_requests_list?jwt=${encodeURIComponent(jwt)}&fjwt=${encodeURIComponent(fjwt)}`);
    },
    failureCallback: (error, dis) => {
      genericErrorMessage(error, dis);
    }
  });
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(DeliveryRequestEdit)
  )
);
