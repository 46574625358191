import React from 'react';
import PropTypes from 'prop-types';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';

const BasicTooltip = ({ toolbarVariant, direction, text, children, delayShow, delayHide }) => (
  <ButtonToolbar className={toolbarVariant}>
    <OverlayTrigger
      key={direction}
      placement={direction}
      delay={{ show: delayShow, hide: delayHide }}
      overlay={<Tooltip>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </ButtonToolbar>
);

BasicTooltip.propTypes = {
  direction: PropTypes.string,
  text: PropTypes.string.isRequired,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

BasicTooltip.defaultProps = {
  direction: 'top',
  delayShow: 250,
  delayHide: 0,
  toolbarVariant: ''
};

export default BasicTooltip;
