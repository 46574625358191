import React, { useRef } from "react";
import { withFormik, Form } from "formik";
import { Button, Table } from "react-bootstrap";
import { Save } from "react-feather";
import * as Yup from "yup";
import QualityRowInput from "./includes/QualityRowInput";

const ProjectForm = props => {
  const {
    errors,
    onHide,
    submitVariant,
    touched,
    values,
    setFieldValue,
    search,
    onRequest
  } = props;
  const { qualitiesAttributes } = values.project;
  const buttonEl = useRef(null);

  const handleButtonClick = () => {
    buttonEl.current.click();
  };

  return (
    <Form className={onRequest ? "opacity-8" : ""}>
      <Table bordered striped hover size="sm">
        <thead>
          <tr>
            <th width="100">Cajas</th>
            <th width="100">Un/Caja</th>
            <th width="100">Total</th>
            <th width="150">Tema</th>
            <th width="150">Usuario</th>
            <th width="100">Fecha</th>
            <th width="115">
              <Button
                className="hidden"
                type="submit"
                ref={buttonEl}
                size="sm"
                variant={submitVariant}
                block
                onClick={onHide}
              >
                <Save width="15" height="15" />
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {qualitiesAttributes.map((quality, index) => (
            <QualityRowInput
              key={`quality-row-${index.toString()}`}
              quality={quality}
              index={index}
              errors={errors}
              touched={touched}
              values={{ quality }}
              setFieldValue={setFieldValue}
              search={search}
              handleButtonClick={handleButtonClick}
            />
          ))}
        </tbody>
      </Table>
    </Form>
  );
};

const setInitialValues = props => {
  const { project } = props;
  return {
    project
  };
};

const validationSchema = Yup.object().shape({});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== "new"
})(ProjectForm);
