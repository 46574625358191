import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { indexSlidesRequest } from '../../requests/slides';
import downloadFile from '../../services/utils';
import { LinkBtn, BasicDropdown } from '../../components';
import SlideDataTable from './SlideDataTable';

class SlideIndex extends Component {
  state = {
    loading: false
  };

  downloadIndex = format => {
    this.setState({ loading: true });
    const { dispatch } = this.props;
    indexSlidesRequest({
      dispatch,
      format,
      successCallback: this.handleSuccessDownload
    });
  };

  handleSuccessDownload = response => {
    downloadFile(response);
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <>
        <Row className="mt-3">
          <Col>
            <h2>Slides</h2>
          </Col>
          <Col md={2}>
            <LinkBtn variant="primary" block to="/slides/new">
              Crear
            </LinkBtn>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={2}>
            <BasicDropdown
              variant="button"
              titleDrop="Download"
              items={[
                {
                  key: '1',
                  title: 'pdf',
                  props: {
                    onClick: () => this.downloadIndex('pdf'),
                    disabled: loading
                  }
                },
                {
                  key: '2',
                  title: 'xlsx',
                  props: {
                    onClick: () => this.downloadIndex('xlsx'),
                    disabled: loading
                  }
                }
              ]}
            />
          </Col>
        </Row>
        <SlideDataTable />
      </>
    );
  }
}

export default withRouter(connect()(SlideIndex));
