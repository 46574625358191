import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FormikInput, BasicTextArea } from '../../components';
import { sendContactFormRequest } from '../../requests/utils';
import { sendAlert } from '../../actions/utils';

const HomeContact = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;
  return (
    <Row className="justify-content-center align-items-center full-height">
      <Col md={4}>
        <h4>Contactar</h4>
        <Form>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                label="E-mail"
                placeholder="Email"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="message">
            {({ field }) => (
              <BasicTextArea
                {...field}
                label="Mensaje"
                placeholder="Mensaje"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            ENVIAR
          </Button>
        </Form>
      </Col>
    </Row>
  );
};

const initialValues = {
  email: '',
  message: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  message: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props, resetForm }) => {
  const { dispatch } = props;
  sendContactFormRequest({
    dispatch,
    params: {
      email: values.email,
      message: values.message
    },
    successCallback: result => {
      resetForm();
      dispatch(sendAlert({ kind: 'success', message: result.data.message }));
    }
  });
};

export default connect()(
  withFormik({
    mapPropsToValues: () => initialValues,
    validationSchema,
    handleSubmit
  })(HomeContact)
);
