import AwesomeDebouncePromise from "awesome-debounce-promise";
import ApiService from "../services/apiService";

export const showProjectRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request("get", `/projects/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const indexProjectsRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = "";
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request("get", `/projects${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const updateProjectRequest = (
  id,
  { dispatch, params, formData, failureCallback, successCallback }
) =>
  ApiService.request("put", `/projects/${id}`, {
    dispatch,
    params,
    formData,
    successCallback,
    failureCallback
  });

export const debounceIndexProjectsRequest = AwesomeDebouncePromise(
  indexProjectsRequest,
  300
);
