import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';

import SlideInfo from './SlideInfo';
import { LinkBtn } from '../../components';
import { showSlideRequest } from '../../requests/slides';

class SlideShow extends Component {
  state = {
    slide: {
      id: '',
      fileInfo: '',
      position: 0,
      caption: '',
      active: ''
    }
  };

  componentDidMount() {
    const { match, dispatch } = this.props;
    const slideId = match.params.id;
    showSlideRequest(slideId, {
      dispatch,
      successCallback: response => this.handleSuccessShow(response)
    });
  }

  handleSuccessShow = response => {
    const slide = camelCaseRecursive(response.data);
    this.setState({ slide });
  };

  render() {
    const { slide } = this.state;
    return (
      <>
        <Row className="mt-3">
          <Col>
            <h2>Slides Show (nombre elemento)</h2>
          </Col>
          <Col md={2}>
            <LinkBtn variant="primary" block to={`/slides/${slide.id}/edit`}>
              Editar
            </LinkBtn>
          </Col>
          <Col md={2}>
            <LinkBtn variant="light" block to="/slides">
              Volver
            </LinkBtn>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <div className="carousel-show">
              <Image src={slide.fileInfo.fileUrl} />
            </div>
          </Col>
          <Col md={6}>
            <SlideInfo slide={slide} />
          </Col>
        </Row>
      </>
    );
  }
}

export default connect()(SlideShow);
