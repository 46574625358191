import React, { Component } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { history } from './app/config/store';
import routes from './app/config/routes';
import { Alert } from './app/components';
import GlobalErrorBoundary from './app/screens/Error/GlobalErrorBoundary';
import { getSettings } from './app/actions/utils';
import './index.scss';

class App extends Component {
  componentDidMount = () => {
    const { dispatch } = this.props;
    dispatch(getSettings());
  };

  render() {
    const { pathname } = history.location;
    const isDeliveryRequest = pathname.includes('/map/delivery_requests');

    return (
      <GlobalErrorBoundary>
        <div className={isDeliveryRequest ? '' : 'container'}>
          <ConnectedRouter history={history}>{routes}</ConnectedRouter>
        </div>
        <Alert />
      </GlobalErrorBoundary>
    );
  }
}

const mapStateToProps = state => {
  const { utils } = state.utils;
  return {
    utils
  };
};

export default connect(mapStateToProps)(App);
