import React, { useRef, useState } from 'react';
import { Row, Col, Button, Popover, Overlay } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarked, faPhoneAlt, faComment } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { formatMessage } from '../../i18n/language';
import { fallbackTextProp, linkToWhatsapp } from '../../services/utils';
import { getStatus } from './status';

import './styles.scss';

const DeliveryRequestListItem = ({ deliveryOrder, handleStart, handleFinish, handlePause }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const {
    externalId,
    clientName,
    providerName,
    providerPhone = '',
    clientPhone = '',
    destinationAddress = ''
  } = deliveryOrder;
  const project = deliveryOrder.project || {};
  const { externalId: projectExternalId, name } = project;
  const status = deliveryOrder.status || 0;
  const destinationIsPresent = !!destinationAddress && destinationAddress.length > 0;
  const notesIsPresent = !!deliveryOrder.notes && !!deliveryOrder.notes.length;
  const providerIsPresent = !!providerName;
  const providerOrClientLabel = providerIsPresent ? 'delivery.provider' : 'delivery.client';
  const providerOrClientName = providerIsPresent ? providerName : clientName;

  let phoneIsPresent;
  let presentPhone;
  if (providerIsPresent) {
    phoneIsPresent = !!providerPhone && providerPhone.length > 4;
    presentPhone = phoneIsPresent ? providerPhone : null;
  } else {
    phoneIsPresent = !!clientPhone && clientPhone.length > 4;
    presentPhone = phoneIsPresent ? clientPhone : null;
  }

  const drawPauseButton = () => {
    const isRunning = status === 1;
    return isRunning ? (
      <Button variant="warning" className="mt-3 col-12" onClick={() => handlePause(externalId)}>
        {formatMessage({ id: 'delivery.pause' })}
      </Button>
    ) : null;
  };

  const drawActionButton = () => {
    const drawButton = status === 0 || status === 1;
    const statusMessageId = status === 0 ? 'delivery.start' : 'delivery.finalize';
    const buttonColor = status === 0 ? 'secoundary' : 'primary';
    const buttonHandler = status === 0 ? handleStart : handleFinish;
    return drawButton ? (
      <Button variant={buttonColor} className="mt-3 col-12" onClick={() => buttonHandler(externalId)}>
        {formatMessage({ id: statusMessageId })}
      </Button>
    ) : null;
  };

  const handleMapButton = () => {
    if (destinationIsPresent) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(destinationAddress)}`);
    }
  };

  const handleTelButton = () => {
    if (phoneIsPresent) {
      window.open('tel:9971863733');
    }
  };

  const handleWhatsappButton = () => {
    if (phoneIsPresent) {
      window.open(linkToWhatsapp(presentPhone));
    }
  };

  const handlePopover = event => {
    if (!notesIsPresent) return;

    setShow(!show);
    setTarget(event.target);
  };

  return (
    <Col xs={12} lg={4} md={6} className="mt-3">
      <div className="inner-card d-flex flex-column">
        <Row>
          <Col>
            <h5 className="mb-3 font-weight-bold">
              {projectExternalId} {name ? '-' : ` - ${formatMessage({ id: 'project.noNameAbbreviated' })}`} {name}
            </h5>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <p className="label">{formatMessage({ id: 'delivery.state' })}:</p>
          </Col>
          <Col xs={8} className="pl-0">
            <div className={`btn-status text-center ${getStatus(status)}`}>
              <p className="paragraph1">{formatMessage({ id: `delivery.${getStatus(status)}` })}</p>
            </div>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col xs={4}>
            <p className="label">{formatMessage({ id: 'delivery.address' })}</p>
          </Col>
          <Col xs={5} className="px-0">
            <p className="paragraph1">{fallbackTextProp(destinationAddress, formatMessage({ id: 'unavailable' }))}</p>
          </Col>
          <Col xs={3} className="text-right">
            <FontAwesomeIcon
              icon={faMapMarked}
              className={destinationIsPresent ? 'icon-clickable' : 'icon-disabled'}
              style={{ width: 26, height: 26 }}
              onClick={handleMapButton}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={4}>
            <p className="label">{formatMessage({ id: providerOrClientLabel })}</p>
          </Col>
          <Col xs={8} className="paragraph2 pl-0">
            <p>{fallbackTextProp(providerOrClientName, formatMessage({ id: 'unavailable' }))}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <p className="label">{formatMessage({ id: 'delivery.phone' })}</p>
          </Col>
          <Col xs={4} className="px-0">
            <p className={`paragraph2 ${phoneIsPresent ? 'text-clip-nowrap' : ''}`}>
              {fallbackTextProp(presentPhone, formatMessage({ id: 'unavailable' }))}
            </p>
          </Col>
          <Col xs={4} className="text-right d-flex aling-items-center justify-content-around flex-wrap">
            <FontAwesomeIcon
              icon={faPhoneAlt}
              style={{ width: 20, height: 18, marginTop: '4px' }}
              className={phoneIsPresent ? 'icon-clickable' : 'icon-disabled'}
              onClick={handleTelButton}
            />
            <Overlay show={show} target={target} placement="bottom" container={ref.current} containerPadding={20}>
              <Popover id="popover-contained">
                <Popover.Content>{deliveryOrder.notes}</Popover.Content>
              </Popover>
            </Overlay>
            <FontAwesomeIcon
              icon={faComment}
              style={{ width: 20, height: 20, marginLeft: '5px', marginTop: '2px' }}
              className={notesIsPresent ? 'icon-clickable' : 'icon-disabled'}
              onClick={handlePopover}
            />
            <FontAwesomeIcon
              icon={faWhatsapp}
              style={{ width: 20, height: 24, marginLeft: '5px' }}
              className={phoneIsPresent ? 'icon-clickable' : 'icon-disabled'}
              onClick={handleWhatsappButton}
            />
          </Col>
        </Row>
        <Row className="fix-bottom">
          <Col>{drawPauseButton()}</Col>
          <Col xs={{ span: 6 }} className="text-right">
            {drawActionButton()}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default DeliveryRequestListItem;
