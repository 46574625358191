import { createIntl, createIntlCache } from "react-intl";

import intlMessagesEN from "./locales/en.json";
import intlMessagesES from "./locales/es.json";
import intlMessagesFR from "./locales/fr.json";
import intlMessagesPT from "./locales/pt.json";
import intlMessagesESPE from "./locales/es-pe.json";
import translations from "./scripts/messagesDefinitions";

export const messages = {
  en: intlMessagesEN,
  es: intlMessagesES,
  fr: intlMessagesFR,
  pt: intlMessagesPT,
  "es-pe": { ...intlMessagesES, ...intlMessagesESPE }
};

export const getCurrentLanguage = () => localStorage.getItem("language") || "es";

const storedIntl = {};
const intlCache = createIntlCache();
const getIntl = currentLanguage => {
  if (storedIntl.currentLanguage !== currentLanguage) {
    storedIntl.currentLanguage = currentLanguage;
    storedIntl.providerContext = createIntl(
      {
        locale: `${currentLanguage}`,
        messages: messages[`${currentLanguage}`]
      },
      intlCache
    );
  }
  return storedIntl.providerContext;
};

export const formatMessage = ({ id, values }) => {
  const { formatMessage: intlFormatMessage } = getIntl(getCurrentLanguage());
  const translationId = translations[id];
  if (process.env.NODE_ENV !== "production") {
    if (!translationId) {
      console.error(`Missing translation for id: ${id}`);
      return `MISSING TRANSLATION: ${id}`;
    }
  }

  return intlFormatMessage(translationId, values);
};
