import ApiService from '../services/apiService';

export const resetPasswordRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/password/reset', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const forgotPasswordRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/password/forgot', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const sendContactFormRequest = ({
  dispatch,
  params,
  failureCallback,
  successCallback
}) =>
  ApiService.request('post', '/pages/contact', {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
