import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip, Col } from 'react-bootstrap';
import Select from 'react-select';
import { formatMessage } from '../../../i18n/language';

const handleDefaultMultiValue = (value, defaultValue, defaultMultiValues) => {
  if (defaultValue && value && value === defaultValue) return defaultMultiValues;
  return undefined;
};

const findDefaultValue = (value, defaultValue, options, props) => {
  const { isMulti, defaultMultiValues } = props;
  if (isMulti) {
    return handleDefaultMultiValue(value, defaultValue, defaultMultiValues);
  }
  if (defaultValue && value && defaultValue !== '' && value !== '' && value === defaultValue) {
    const vAttribute = options.filter(e => defaultValue === e.value);
    return vAttribute[0];
  }
  if (value === '') return null;
  return undefined;
};

const FormikSelect = ({
  options,
  label,
  abbr,
  customOption,
  error,
  touched,
  helpText,
  name,
  defaultValue,
  value,
  isDisabled,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  margin,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const customStyles = {
    control: provided => {
      console.log('provided', provided);
      const borderRadius = '25px';
      return { ...provided, borderRadius };
    }
  };
  return (
    <Form.Group
      controlId={label}
      as={Col}
      className={`${isDisabled ? 'disabled' : ''} ${error && touched ? 'is-invalid' : ''} ${margin}`}
    >
      {label && (
        <Form.Label className="d-flex align-items-center">
          {label} {abbr && <abbr className="text-danger ml-1">*</abbr>}
          {tooltipText && (
            <ButtonToolbar className="ml-2">
              <OverlayTrigger
                key={direction}
                placement={direction}
                delay={{ show: delayShow, hide: delayHide }}
                overlay={<Tooltip>{tooltipText}</Tooltip>}
              >
                ?
              </OverlayTrigger>
            </ButtonToolbar>
          )}
        </Form.Label>
      )}
      <Select
        className={`${isOpen ? 'border-on ' : ''}`}
        placeholder={formatMessage({ id: 'formik.search' })}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        loadingMessage={formatMessage({ id: 'formik.loading' })}
        noOptionsMessage={formatMessage({ id: 'formik.noOptions' })}
        options={options}
        isSearchable
        name={name}
        value={findDefaultValue(value, defaultValue, options, props)}
        isDisabled={isDisabled}
        {...props}
        style={customStyles}
      />
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

FormikSelect.propTypes = {
  delayHide: PropTypes.number,
  delayShow: PropTypes.number,
  direction: PropTypes.string,
  toolbarVariant: PropTypes.string,
  tooltipText: PropTypes.string
};

FormikSelect.defaultProps = {
  delayHide: 0,
  delayShow: 250,
  direction: 'top',
  toolbarVariant: '',
  tooltipText: ''
};

export default FormikSelect;
