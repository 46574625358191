import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { indexSlidesRequest } from '../../requests/slides';
import { Slides } from '../../components';

class Home extends Component {
  constructor() {
    super();

    this.state = {
      slides: []
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    indexSlidesRequest({
      dispatch,
      params: {
        actives: true,
        sort_direction: 'asc',
        sort_column: 'position'
      },
      successCallback: result => {
        this.setState({ slides: camelCaseRecursive(result.data.data) });
      }
    });
  };

  render() {
    const { slides } = this.state;

    return (
      <>
        <Slides items={slides} specific="carousel-home mb-4" />
        <Row>
          <Col>
            <h1>R1 C1</h1>
          </Col>
          <Col>
            <h3>R1 C2</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>R2 C1</h1>
          </Col>
          <Col>
            <h3>R2 C2</h3>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { slides } = state.utils;
  return {
    slides
  };
};

export default connect(mapStateToProps)(Home);
