/* eslint-disable no-await-in-loop */
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Image, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { CheckCircle, FileText, Trash } from 'react-feather';

import LoadSpiner from '../Spinner';
import defaultImg from '../../../assets/images/default.png';
import { formatMessage } from '../../../i18n/language';

class DropzoneComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files,
      isDisabled: props.disabled || false,
      maxFiles: props.multiple ? props.maxFiles || undefined : 1
    };
  }

  removeFile = fileId => {
    const { files, maxFiles } = this.state;
    const { onDelete } = this.props;
    const newFiles = [];
    let fileToRemove;

    files.forEach(file => {
      if (file.code !== fileId) {
        newFiles.push(file);
      } else {
        fileToRemove = file;
      }
    });
    this.setState({ files: newFiles });
    onDelete(fileToRemove);
    if (newFiles.length < maxFiles) {
      this.setState({ isDisabled: false });
    }
  };

  removePersistedFile = fileId => {
    const { maxFiles } = this.state;
    const { persistedFiles, onDeletePersistedFiles } = this.props;
    const newFiles = [];
    let fileToRemove;

    persistedFiles.forEach(file => {
      if (file.id !== fileId) {
        newFiles.push(file);
      } else {
        fileToRemove = file;
      }
    });
    onDeletePersistedFiles(fileToRemove);
    if (newFiles.length < maxFiles) {
      this.setState({ isDisabled: false });
    }
  };

  addFiles = async newFiles => {
    const { duplicateFile, batchNumber, onDrop, onDropUploaded } = this.props;
    const { maxFiles } = this.state;

    await newFiles.forEach(async file => {
      const { files } = this.state;

      if (maxFiles !== undefined && files.length + 1 >= maxFiles) {
        this.setState({ isDisabled: true });
        if (files.length >= maxFiles) return;
      }
      if (!duplicateFile) {
        const duplicate = files.filter(f => f.name === file.name && f.size === file.size && f.type === file.type);
        if (duplicate.length) return;
      }

      const reader = new FileReader();
      const code = Math.floor(Math.random() * 10000000000).toString(16);

      this.setState({
        files: [
          ...files,
          {
            code,
            name: file.name,
            result: defaultImg,
            size: file.size,
            type: file.type,
            file,
            loaded: false,
            uploaded: false
          }
        ]
      });
      reader.onloadend = () => {
        const { files: loadFiles } = this.state;
        const newFile = loadFiles.map(f => {
          if (f.code === code) return { ...f, result: reader.result };
          return f;
        });
        this.setState({ files: newFile });
      };
      reader.readAsDataURL(file);
    });

    const { files: totalFiles } = this.state;
    const { id: recordId } = this.props;
    const addedFiles = [];
    let newTotalFiles = [];

    totalFiles.forEach(f => {
      if (!f.loaded) addedFiles.push(f);
      newTotalFiles.push({ ...f, loaded: true });
    });
    this.setState({ files: newTotalFiles });

    const batchN = batchNumber || addedFiles.length;
    const rangeBatchNumber = Math.ceil(addedFiles.length / batchN);
    let batchFiles;

    for (let index = 0; index < rangeBatchNumber; index += 1) {
      batchFiles = addedFiles.slice(index * batchN, (index + 1) * batchN);
      const filesToFetch = batchFiles.map(bf => ({
        document_key: bf.code,
        document: bf.file
      }));
      const response = await onDrop(recordId, filesToFetch);
      if (response && response.status === 201) {
        const result = await response.data;
        const { files: totalFilesLoaded } = this.state;
        newTotalFiles = totalFilesLoaded.map(ntf => {
          const fileUploaded = result.filter(r => r.document_key === ntf.code)[0];
          return fileUploaded ? { ...ntf, uploaded: true, id: fileUploaded.id } : ntf;
        });
      }
      onDropUploaded(newTotalFiles);
      this.setState({ files: newTotalFiles });
    }
  };

  textDropzone = ({ isDragActive, isDragReject, isDisabled, isFileTooLarge }) => {
    const {
      texts: { textDefault, reject, active, disabled, tooLarge }
    } = this.props;
    if (isDisabled) return disabled;
    if (isDragReject) return reject;
    if (isFileTooLarge) return tooLarge;
    if (isDragActive) return active;
    return textDefault;
  };

  classContainer = ({ isDragActive, isDragReject, isDisabled, isFileTooLarge }) => {
    const {
      classes: { container, reject, active, disabled }
    } = this.props;
    let className = container;
    if (isDragReject || isFileTooLarge) {
      className += ` ${reject}`;
      return className;
    }
    if (isDragActive) className += ` ${active}`;
    if (isDisabled) className += ` ${disabled}`;
    return className;
  };

  preview = files =>
    files.map(file => {
      return (
        <Col md={4} key={file.code}>
          <div className="dropzone-box">
            <div className="box-img">
              <div className="content">
                {file.type.includes('image') ? (
                  <Image src={file.result} className="image-fit" width="120px" height="90px" />
                ) : (
                  <FileText className="icon-file-text" strokeWidth="1.5" />
                )}
                <div className="hover-dropzone">
                  {file.uploaded ? (
                    <CheckCircle className="big-svg text-success mb-2" strokeWidth="1.5" />
                  ) : (
                    <LoadSpiner />
                  )}
                  {file.uploaded && (
                    <Button
                      variant="danger"
                      className="arrow-btn-color right"
                      size="sm"
                      onClick={() => this.removeFile(file.code)}
                    >
                      <Trash className="small-svg" strokeWidth="1.5" />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <br />
          </div>
        </Col>
      );
    });

  previewPersistedFiles = files =>
    files.map(file => (
      <Col md={4} key={file.id}>
        <div className="dropzone-box">
          <div className="box-img">
            <div className="content">
              {file.type.includes('image') ? (
                <Image src={file.url} className="image-fit" width="140px" height="90px" />
              ) : (
                <FileText className="icon-file-text" strokeWidth="1.5" />
              )}
              <div className="hover-dropzone">
                <Button
                  variant="danger"
                  className="arrow-btn-color right"
                  size="sm"
                  onClick={() => this.removePersistedFile(file.id)}
                >
                  <Trash className="small-svg" strokeWidth="1.5" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    ));

  render() {
    const { files, isDisabled } = this.state;
    const { multiple, maxSize, minSize, fileAccept, persistedFiles } = this.props;
    return (
      <Dropzone
        onDrop={newFiles => this.addFiles(newFiles)}
        disabled={isDisabled}
        multiple={multiple}
        maxSize={maxSize}
        minSize={minSize}
        accept={fileAccept}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
          const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
          return (
            <section className="dropzone-section">
              <Row className="full-row">
                {this.previewPersistedFiles(persistedFiles)}
                {this.preview(files)}
              </Row>
              <div
                {...getRootProps()}
                className={this.classContainer({
                  isDragActive,
                  isDragReject,
                  isDisabled,
                  isFileTooLarge
                })}
              >
                <input {...getInputProps()} />
                {this.textDropzone({
                  isDragActive,
                  isDragReject,
                  isDisabled,
                  isFileTooLarge
                })}
              </div>
            </section>
          );
        }}
      </Dropzone>
    );
  }
}

DropzoneComponent.propTypes = {
  texts: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  persistedFiles: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.number,
  onDrop: PropTypes.func,
  onDropUploaded: PropTypes.func,
  onDelete: PropTypes.func,
  onDeletePersistedFiles: PropTypes.func,
  batchNumber: PropTypes.number,
  duplicateFile: PropTypes.bool,
  maxFiles: PropTypes.number,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  fileAccept: PropTypes.string
};

DropzoneComponent.defaultProps = {
  texts: {
    textDefault: (
      <div>
        <p className="p-t-15">{formatMessage({ id: 'dropzone.uploadPhotos' })}</p>
      </div>
    ),
    reject: formatMessage({ id: 'dropzone.reject' }),
    active: formatMessage({ id: 'dropzone.active' }),
    disabled: formatMessage({ id: 'dropzone.disabled' }),
    tooLarge: formatMessage({ id: 'dropzone.tooLarge' })
  },
  classes: {
    container: 'dropzone-container',
    reject: 'dropzone-reject',
    active: 'dropzone-active',
    disabled: 'dropzone-disabled'
  },
  files: [],
  persistedFiles: [],
  id: Date.now(),
  onDrop: (id, files) => console.log(id, files),
  onDropUploaded: files => console.log(files),
  onDelete: file => console.log(file),
  onDeletePersistedFiles: file => console.log(file),
  batchNumber: undefined,
  duplicateFile: true,
  maxFiles: undefined,
  disabled: false,
  multiple: true,
  maxSize: undefined,
  minSize: undefined,
  fileAccept: ''
};

export default DropzoneComponent;
