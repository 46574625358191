import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { validateToken } from '../../../actions/auth';
import PrincipalNavbar from './PrincipalNavbar';

class NavbarLogged extends Component {
  componentDidMount() {
    const { user, dispatch } = this.props;
    if (user.id === 0) {
      dispatch(validateToken());
    }
  }

  render() {
    const { children, user } = this.props;
    return (
      <PrincipalNavbar>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/components">
              Componentes
            </Nav.Link>
          </Nav>
          <Nav>
            <NavDropdown alignRight title={user.email} id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/profile">
                Perfil
              </NavDropdown.Item>
              {children}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </PrincipalNavbar>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.auth;
  return { user };
};

export default connect(mapStateToProps)(NavbarLogged);
