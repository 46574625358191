/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import { withFormik, Form } from 'formik';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Dropzone } from '../../components';
import {
  createDropZoneFileRequest,
  deleteDropZoneFileRequest
} from '../../requests/dropzone';

class ProfileForm extends Component {
  handleOnDrop = (code, documents) => {
    const { dispatch } = this.props;
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'user_avatar',
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      params: dropzoneFiles,
      formData: true,
      successCallback: response => response
    });
  };

  handleOnDeletePersistedFiles = document => {
    const { destroyDocumentRequest } = this.props;
    const documentDestroy = {
      document_type: 'user_avatar',
      document_id: document.id
    };
    return destroyDocumentRequest(documentDestroy);
  };

  render() {
    const {
      onHide,
      isValid,
      submitVariant,
      dispatch,
      updateUserAvatar,
      documentCode,
      maxAvatarSize,
      user
    } = this.props;

    return (
      <Form>
        <div className="form-group">
          <label className="form-label">Documentos</label>
          <Dropzone
            id={documentCode}
            persistedFiles={user.persistedAvatar}
            maxSize={maxAvatarSize} // 20MB
            files={user.currentAvatar}
            onDrop={this.handleOnDrop}
            multiple={false}
            onDropUploaded={documents => updateUserAvatar(documents)}
            onDelete={document =>
              deleteDropZoneFileRequest(document.id, {
                dispatch,
                params: { dropZoneFile: { documentKey: document.code } }
              })
            }
            onDeletePersistedFiles={this.handleOnDeletePersistedFiles}
          />
          <small className="text-muted form-text">
            Tamaño máximo 20MB por archivo
          </small>
        </div>
        <Button
          type="submit"
          variant={submitVariant}
          size="lg"
          block
          disabled={!isValid}
          onClick={onHide}
        >
          Editar
        </Button>
      </Form>
    );
  }
}

const setInitialValues = props => {
  const { user } = props;
  return {
    user
  };
};

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  const updatedValues = values;
  updatedValues.user.documentCode = props.documentCode;
  formRequest(updatedValues);
};

export default (
  withFormik({
    mapPropsToValues: props => setInitialValues(props),
    // validationSchema,
    handleSubmit,
    enableReinitialize: true
  })(connect()(ProfileForm))
);
