import React from "react";
import AsyncSelect from "react-select/async";

const RemoteSelect = ({
  disabled,
  value,
  request,
  onChange,
  defaultValue,
  ...moreProps
}) => (
  <AsyncSelect
    placeholder="Buscar..."
    loadingMessage={() => "Cargando..."}
    noOptionsMessage={() => "No hay opciones"}
    defaultValue={defaultValue}
    value={value}
    defaultOptions
    loadOptions={request}
    onChange={onChange}
    isDisabled={disabled}
    {...moreProps}
  />
);

export default RemoteSelect;
