import React, { useRef, useEffect } from "react";
import { Button, Row, Col, Form as BootstrapForm } from "react-bootstrap";
import * as Yup from "yup";
import { withFormik, Field, Form, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { geoCode } from "../../config/store";
import { FormikInput, FormikSelect, InputRemoteSelect } from "../../components";
import styles from "./styles";
import { formatMessage } from "../../i18n/language";

const deliveryVehicle = [
  { label: "MotoFex", value: 1 },
  { label: "Auto", value: 2 },
  { label: "Furgón", value: 3 },
  { label: "Camioneta", value: 5 },
  { label: "Mini camión abierto ", value: 7 },
  { label: "Mini camión cerrado ", value: 8 }
];
const deliveryRegOrigen = [
  { label: "Sí", value: 1 },
  { label: "No", value: 0 }
];
const scrollToRef = ref => window.scrollTo(0, ref.current.offsetTop);

const DeliveryRequestForm = props => {
  const {
    values,
    onHide,
    errors,
    touched,
    setFieldValue,
    formRequest,
    onRequest,
    pricingDelivery,
    delivery
  } = props;

  const buttonGetDelivery = useRef(null);
  const executeScroll = () => scrollToRef(buttonGetDelivery);

  const {
    originAddress: deliveryOriginAddress,
    destinationAddress: deliveryDestinationAddress
  } = delivery;

  const defaultOriginAddressValues = [
    {
      label: deliveryOriginAddress,
      value: deliveryOriginAddress
    }
  ];

  const defaultDestinationAddressValues = [
    {
      label: deliveryDestinationAddress,
      value: deliveryDestinationAddress
    }
  ];

  useEffect(() => {
    if (pricingDelivery.distancia) {
      executeScroll();
    }
  }, [pricingDelivery.distancia]);

  const exchangeAddress = () => {
    const {
      originAddress,
      destinationAddress,
      originLat,
      originLng,
      destinationLat,
      destinationLng
    } = values.delivery;

    setFieldValue("delivery", {
      ...values.delivery,
      destinationAddress: originAddress,
      destinationLat: originLat,
      destinationLng: originLng,
      originAddress: destinationAddress,
      originLat: destinationLat,
      originLng: destinationLng
    });
  };

  const addressSearch = (address, callback) => {
    geoCode.fromAddress(address).then(
      response => {
        callback([
          {
            label: response.results[0].formatted_address,
            value: response.results[0].formatted_address
          }
        ]);
      },
      error => {
        console.error(error);
      }
    );
  };

  const handleChangeAdress = (data, addressType) => {
    setFieldValue(`delivery[${addressType}Address]`, data ? data.value : "");
    geoCode.fromAddress(data.value).then(
      response => {
        const { lat, lng } = response.results[0].geometry.location;
        setFieldValue(
          `delivery[${addressType}Address]`,
          data ? data.value : ""
        );
        setFieldValue(`delivery[${addressType}Lat]`, lat);
        setFieldValue(`delivery[${addressType}Lng]`, lng);
      },
      error => {
        console.error(error);
      }
    );
  };

  return (
    defaultOriginAddressValues && (
      <>
        <Form style={{ ...styles.container, ...styles.text }}>
          <Row>
            <Col md={{ span: 6 }}>
              <BootstrapForm.Row>
                <Field name="delivery[originAddress]">
                  {({ field }) => (
                    <InputRemoteSelect
                      {...field}
                      styles={{ ...styles.text, width: "100%" }}
                      label={
                        <>
                          <span>
                            {formatMessage({
                              id: "delivery.address.origin"
                            })}
                          </span>
                          <FontAwesomeIcon
                            icon={faSync}
                            onClick={() => exchangeAddress()}
                            className="clickable"
                            style={{ marginInline: 10 }}
                          />
                        </>
                      }
                      defaultOptions={defaultOriginAddressValues}
                      onChange={data => handleChangeAdress(data, "origin")}
                      defaultValue={{
                        label: values.delivery.originAddress,
                        value: values.delivery.originAddress
                      }}
                      value={{
                        label: values.delivery.originAddress,
                        value: values.delivery.originAddress
                      }}
                      request={addressSearch}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
              <BootstrapForm.Row>
                <Field name="delivery[destinationAddress]">
                  {({ field }) => (
                    <InputRemoteSelect
                      {...field}
                      styles={{ ...styles.text, width: "100%" }}
                      label={formatMessage({
                        id: "delivery.address.destination"
                      })}
                      defaultOptions={defaultDestinationAddressValues}
                      onChange={data => handleChangeAdress(data, "destination")}
                      defaultValue={{
                        label: values.delivery.destinationAddress,
                        value: values.delivery.destinationAddress
                      }}
                      value={{
                        label: values.delivery.destinationAddress,
                        value: values.delivery.destinationAddress
                      }}
                      request={addressSearch}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>

              <BootstrapForm.Row>
                <Field name="delivery[clientName]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      disabled
                      style={styles.text}
                      inputType="text"
                      label={formatMessage({
                        id: "delivery.client.name"
                      })}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
              <BootstrapForm.Row>
                <Field name="delivery[clientPhone]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      disabled
                      style={styles.text}
                      inputType="text"
                      label={formatMessage({
                        id: "delivery.client.phone"
                      })}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
            </Col>
            <Col md={{ span: 6 }}>
              <BootstrapForm.Row>
                <Field name="delivery[description]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      abbr
                      style={styles.text}
                      inputType="text"
                      label={formatMessage({
                        id: "delivery.description"
                      })}
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
              <BootstrapForm.Row>
                <Field name="delivery[roundTrip]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      style={styles.text}
                      options={deliveryRegOrigen}
                      label={formatMessage({
                        id: "delivery.roundtrip"
                      })}
                      defaultValue="released"
                      onChange={data =>
                        setFieldValue(field.name, data ? data.value : "")
                      }
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
              <BootstrapForm.Row>
                <Field name="delivery[vehicle]">
                  {({ field }) => (
                    <FormikSelect
                      {...field}
                      abbr
                      style={styles.text}
                      options={deliveryVehicle}
                      label={formatMessage({
                        id: "delivery.vehicle"
                      })}
                      defaultValue="released"
                      onChange={data =>
                        setFieldValue(field.name, data ? data.value : "")
                      }
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>

              <BootstrapForm.Row>
                <Field name="delivery[extra]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      style={styles.text}
                      inputType="text"
                      label="Extra"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
              <BootstrapForm.Row>
                <Field name="delivery[voucher]">
                  {({ field }) => (
                    <FormikInput
                      {...field}
                      style={styles.text}
                      inputType="text"
                      label="Cupón"
                      error={getIn(errors, field.name)}
                      touched={getIn(touched, field.name)}
                    />
                  )}
                </Field>
              </BootstrapForm.Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={styles.text}
                disabled={onRequest}
                type="submit"
                variant="success"
                block
                onClick={onHide}
              >
                {formatMessage({
                  id: "delivery.quote"
                }).toUpperCase()}
              </Button>
            </Col>
          </Row>
          <Row
            className="justify-content-md-center"
            style={{ margin: "15px 0", color: "#484848" }}
          >
            {pricingDelivery.distancia && (
              <>
                <Row
                  className="justify-content-md-center"
                  style={{
                    width: "70%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 15
                  }}
                >
                  <Col>Total</Col>
                  <Col>
                    {formatMessage({
                      id: "delivery.distance"
                    })}
                  </Col>
                </Row>
                <Row
                  className="justify-content-md-center"
                  style={{
                    width: "70%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 20
                  }}
                >
                  <Col>
                    <p>${pricingDelivery.total}</p>
                  </Col>
                  <Col>
                    <p> {pricingDelivery.distancia} km </p>
                  </Col>
                </Row>
                <Button
                  disabled={onRequest}
                  style={styles.text}
                  ref={buttonGetDelivery}
                  type="submit"
                  variant="success"
                  block
                  onClick={() => formRequest(values)}
                >
                  SOLICITAR
                </Button>
              </>
            )}
          </Row>
        </Form>
      </>
    )
  );
};
const setInitialValues = props => {
  const {
    delivery: {
      originLat,
      originLng,
      originAddress,
      destinationLat,
      destinationLng,
      destinationAddress,
      description,
      clientName,
      clientPhone,
      extra,
      startTime,
      endTime
    }
  } = props;

  return {
    delivery: {
      originLat,
      originLng,
      vehicle: "",
      roundTrip: "",
      originAddress,
      destinationLat,
      destinationLng,
      destinationAddress,
      description: description || "",
      clientName,
      clientPhone,
      extra: extra || "",
      voucher: "",
      startTime,
      endTime
    }
  };
};

const validationSchema = Yup.object().shape({
  delivery: Yup.object().shape({
    originLat: Yup.number().required("Requerido"),
    originLng: Yup.number().required("Requerido"),
    originAddress: Yup.string().required("Requerido"),
    destinationLat: Yup.number().required("Requerido"),
    destinationLng: Yup.number().required("Requerido"),
    destinationAddress: Yup.string().required("Requerido"),
    description: Yup.string().required("Requerido"),
    clientName: Yup.string().required("Requerido"),
    clientPhone: Yup.string().required("Requerido"),
    vehicle: Yup.number().required("Requerido"),
    roundTrip: Yup.number().required("Requerido"),
    extra: Yup.string(),
    voucher: Yup.string(),
    startTime: Yup.string(),
    endTime: Yup.string()
  })
});

const handleSubmit = (values, { props }) => {
  const { formPricingRequest } = props;
  formPricingRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== "new"
})(DeliveryRequestForm);
