import React, { Component } from 'react';
import { Row, Col, FormControl, Form, Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import PropTypes from 'prop-types';

const Debounce = AwesomeDebouncePromise(value => value, 500);

class ComponentDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: '',
      endDate: '',
      textInput: '',
      page: 0,
      RowsPage: props.RowsPage,
      column: '',
      direction: 'desc'
    };
  }

  componentDidMount() {
    this.setRequest();
  }

  componentDidUpdate(prevProps) {
    const { moreData } = this.props;
    if (moreData !== prevProps.moreData) {
      this.setRequest();
    }
  }

  setRequest = params => {
    const { handleSortCase, resourceRequest, moreData } = this.props;
    const {
      RowsPage,
      page,
      textInput,
      direction,
      column,
      startDate,
      endDate
    } = this.state;
    let sortColumn = { sort_column: column };
    if (handleSortCase) {
      sortColumn = handleSortCase(column)
    }
    resourceRequest({
      query: textInput,
      date_from: startDate,
      date_to: endDate,
      display_start: page * RowsPage,
      display_length: RowsPage,
      sort_direction: direction,
      ...sortColumn,
      ...moreData,
      ...params
    });
  };

  onChangePage = page => {
    const { RowsPage } = this.state;
    this.setState({
      page: page - 1
    });

    this.setRequest({
      display_start: (page - 1) * RowsPage
    });
  };

  handleRequest = () => {
    this.setRequest({
      display_start: 0
    });
  };

  onChangeRowsPage = RowsPage => {
    this.setState({
      RowsPage
    });

    this.setRequest({
      display_start: 0,
      display_length: RowsPage
    });
  };

  handleDatepickerChange = async (value, name) => {
    this.setState({
      [name]: value || '',
      page: 0
    });
    await Debounce(value);
    this.setRequest();
  };

  handleInputChange = async event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      page: 0
    });
    await Debounce(value);
    this.setRequest();
  };

  handleSort = (column, direction) => {
    this.setState({
      direction,
      column: column.selector,
      page: 0
    }, () => {
      this.setRequest({
        display_start: 0,
        sort_direction: direction,
      })
    });
  };

  render() {
    const {
      columns,
      onRequest,
      data,
      totalRows,
      RowsPage,
      expandable
    } = this.props;
    const { textInput, startDate, endDate } = this.state;
    return (
      <div className="mt-4" style={{ position: 'relative' }}>
        {onRequest && (
          <div
            className="containerSpinnerLoad"
            style={{ position: 'absolute', height: '100%' }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        <DataTable
          className="custom-data-table"
          pagination
          noDataComponent={onRequest ? '' : 'No se encontraron datos.'}
          paginationServer
          expandableRows={expandable}
          paginationTotalRows={totalRows}
          paginationPerPage={RowsPage}
          paginationRowsPerPageOptions={[5, 10, 15]}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPage}
          paginationComponentOptions={{
            rowsPerPageText: 'Filas por pagina:',
            rangeSeparatorText: 'de'
          }}
          sortServer
          onSort={(column, direction) => this.handleSort(column, direction)}
          sortFunction={a => a}
          noHeader
          subHeader
          disabled={onRequest}
          columns={columns}
          data={data}
          subHeaderComponent={
            <Row>
              <Col xs={4}>
                <Form.Group>
                  <DatePicker
                    placeholderText="Desde"
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    showYearDropdown
                    selected={startDate}
                    onChange={date =>
                      this.handleDatepickerChange(date, 'startDate')
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <DatePicker
                    placeholderText="Hasta"
                    dateFormat="dd-MM-yyyy"
                    className="form-control"
                    showYearDropdown
                    selected={endDate}
                    onChange={date =>
                      this.handleDatepickerChange(date, 'endDate')
                    }
                  />
                </Form.Group>
              </Col>
              <Col xs={4}>
                <Form.Group>
                  <FormControl
                    placeholder="Buscar"
                    name="textInput"
                    value={textInput}
                    onChange={this.handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
        />
      </div>
    );
  }
}

ComponentDataTable.propTypes = {
  onRequest: PropTypes.bool,
  totalRows: PropTypes.number,
  RowsPage: PropTypes.number,
  expandable: PropTypes.bool,
  moreData: PropTypes.bool,
  resourceRequest: PropTypes.func
};

ComponentDataTable.defaultProps = {
  onRequest: false,
  totalRows: 0,
  RowsPage: 10,
  expandable: false,
  moreData: '',
  resourceRequest: () => null
};

export default ComponentDataTable;
