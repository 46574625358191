/* eslint-disable camelcase */
import React from "react";
import { useDispatch } from "react-redux";
import { withFormik, Field, Form, getIn } from "formik";
import * as Yup from "yup";
import { Button, Row, Col, Form as BootstrapForm } from "react-bootstrap";

import { FormikInput, CalculatedField, Dropzone } from "../../components";
import { createDropZoneFileRequest } from "../../requests/dropzone";

const QualityForm = props => {
  const dispatch = useDispatch();
  const { values, errors, touched, setFieldValue, onRequest, user } = props;

  const handleDropzoneDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: "project_review_image",
        documents
      }
    };
    return createDropZoneFileRequest({
      dispatch,
      formData: true,
      params: dropzoneFiles,
      successCallback: r => r
    });
  };

  const handleDropzoneUploaded = files => {
    setFieldValue(
      "quality.documentKeys",
      files.map(file => file.code)
    );
    setFieldValue("quality.filesCount", files.lenght);
  };

  const setState = state => {
    setFieldValue("quality.state", state);
  };

  return (
    <Form>
      {user ? null : (
        <BootstrapForm.Row>
          <Field name="quality[userLegalId]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                label="RUT"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </BootstrapForm.Row>
      )}
      <BootstrapForm.Row>
        <Field name="quality[boxes]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              label="Cajas"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </BootstrapForm.Row>
      <BootstrapForm.Row>
        <Field name="quality[unitsPerBox]">
          {({ field }) => (
            <FormikInput
              {...field}
              abbr
              inputType="number"
              label="Un/Caja"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </BootstrapForm.Row>
      <BootstrapForm.Row>
        <Field name="quality[totalUnits]">
          {({ field }) => (
            <CalculatedField
              {...field}
              abbr
              inputType="number"
              label="Total"
              className="asReadOnly"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
              values={values}
              setFieldValue={setFieldValue}
            />
          )}
        </Field>
      </BootstrapForm.Row>
      <BootstrapForm.Row>
        <Field name="quality[theme]">
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="text"
              label="Tema"
              autoComplete="off"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </BootstrapForm.Row>
      <BootstrapForm.Row>
        <Field name="quality[notes]">
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="text"
              label="Observaciones"
              autoComplete="off"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </BootstrapForm.Row>
      <BootstrapForm.Row>
        <Field name="quality[state]">
          {({ field }) => (
            <FormikInput
              {...field}
              inputType="hidden"
              label=""
              autoComplete="off"
              error={getIn(errors, field.name)}
              touched={getIn(touched, field.name)}
            />
          )}
        </Field>
      </BootstrapForm.Row>
      <BootstrapForm.Row>
        <div className="form-group col">
          <Dropzone
            onDrop={handleDropzoneDrop}
            onDropUploaded={handleDropzoneUploaded}
          />
        </div>
      </BootstrapForm.Row>
      <Row className="d-flex justify-content-end mb-3">
        <Col>
          <Button
            disabled={onRequest}
            type="submit"
            variant="danger"
            block
            onClick={() => {
              setState("rejected");
            }}
          >
            Rechazar
          </Button>
        </Col>
        <Col>
          <Button
            disabled={onRequest}
            type="submit"
            variant="success"
            block
            onClick={() => {
              setState("released");
            }}
          >
            Liberar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const setInitialValues = props => {
  const {
    id,
    boxes,
    unitsPerBox,
    totalUnits,
    notes,
    theme,
    userName,
    userLegalId,
    projectId,
    state
  } = props.quality;

  return {
    quality: {
      id,
      boxes,
      unitsPerBox,
      totalUnits,
      notes,
      theme,
      userName,
      userLegalId,
      projectId,
      state,
      documentKeys: []
      // image: getIn(fileInfo, 'fileSignature'),
    }
  };
};

const validationSchema = Yup.object().shape({
  quality: Yup.object().shape({
    // image: Yup.mixed().required('Requerido'),
    boxes: Yup.number().required("Requerido"),
    // .typeError('Debes escoger un número')
    // .integer('Debe ser un numero entero')
    unitsPerBox: Yup.number().required("Requerido"),
    totalUnits: Yup.number().required("Requerido"),
    theme: Yup.string(),
    notes: Yup.string(),
    userName: Yup.string(),
    userLegalId: Yup.string(),
    projectId: Yup.number().required("Requerido"),
    // documentKeys: Yup.array(),
    filesCount: Yup.number()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== "new"
})(QualityForm);
