import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import camelCaseRecursive from "camelcase-keys-recursive";
import snakeCaseKeys from "snakecase-keys";
import {
  showProjectRequest,
  updateProjectRequest
} from "../../requests/projects";
import ProjectForm from "./ProjectForm";
import { sendAlert } from "../../actions/utils";

const ProjectEdit = props => {
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(true);
  const [project, setProject] = useState({
    name: "",
    quantity: 0,
    total_units: 0,
    external_id: "",
    qualitiesAttributes: []
  });

  const {
    match: {
      params: { id: projectId }
    },
    location: { search }
  } = props;

  const handleSuccessShow = response => {
    setProject(camelCaseRecursive(response.data));
    setOnRequest(false);
  };

  const getProjectData = () => {
    showProjectRequest(projectId, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(getProjectData, []);

  const handleSuccessUpdate = response => {
    setOnRequest(false);
    setProject(camelCaseRecursive(response.data));
  };

  const handleFailureUpdate = error => {
    const { response } = error;
    dispatch(sendAlert({ kind: "error", message: response.data.message }));
  };

  const handleUpdateRequest = values => {
    setOnRequest(true);
    const sendParams = snakeCaseKeys(values, { exclude: ["_destroy"] });

    updateProjectRequest(projectId, {
      dispatch,
      params: sendParams,
      formData: false,
      successCallback: handleSuccessUpdate,
      failureCallback: handleFailureUpdate
    });
  };

  return (
    <>
      {onRequest ? (
        <div
          className="containerSpinnerLoad"
          style={{ position: "absolute", height: "100%" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        ""
      )}
      <ProjectForm
        onRequest={onRequest}
        search={search}
        project={project}
        formRequest={handleUpdateRequest}
      />
    </>
  );
};

export default ProjectEdit;
