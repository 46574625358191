import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import "./styles.scss";

const LoadSpinner = props => {
  const { position } = props;
  return (
    <div
      className="containerSpinnerLoad"
      style={{ position, height: "100%", width: "98%" }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
};

LoadSpinner.propTypes = {
  position: PropTypes.string
};
LoadSpinner.defaultProps = {
  position: "absolute"
};

export default LoadSpinner;
