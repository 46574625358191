import AwesomeDebouncePromise from 'awesome-debounce-promise';
import ApiService from '../services/apiService';

export const indexDeliveryOrders = ({ dispatch, format, params = {}, failureCallback, successCallback }) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('get', `/delivery_orders${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const findOrCreateDeliveryOrderRequest = (
  deliveryOrderId,
  { dispatch, format, params = {}, failureCallback, successCallback }
) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }

  ApiService.request('get', `/delivery_orders/${deliveryOrderId}/${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const getDeliveryPricingRequest = (
  projectId,
  { dispatch, format, params = {}, failureCallback, successCallback }
) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('post', `/projects/${projectId}/delivery_orders/delivery_pricing${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const updateDeliveryOrder = (
  deliveryOrderId,
  { dispatch, format, params = {}, failureCallback, successCallback }
) => {
  let formatt = '';
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request('put', `/delivery_orders/${deliveryOrderId}/${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const noop = AwesomeDebouncePromise(() => {}, 300);
