import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PrincipalNavbar from './PrincipalNavbar';

const NavbarTop = () => (
  <PrincipalNavbar>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link as={Link} to="/">
          Home
        </Nav.Link>
        <Nav.Link as={Link} to="/components">
          Componentes
        </Nav.Link>
      </Nav>
      <Nav>
        <Nav.Link as={Link} to="/login">
          Iniciar Sesión
        </Nav.Link>
        <Nav.Link as={Link} to="/register">
          Crear Cuenta
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </PrincipalNavbar>
);

export default NavbarTop;
