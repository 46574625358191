/* eslint-disable jsx-a11y/label-has-for */
import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import defaultImg from '../../../assets/images/default.png';
import './styles.scss';

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      name: 'Elegir Archivo'
    };
  }

  _handleImageChange(e) {
    const { onChange } = this.props;
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        url: reader.result,
        name: file.name
      });
    };
    reader.readAsDataURL(file);
    onChange(file);
  }

  render() {
    const { url, name } = this.state;
    const { imageUrl } = this.props;
    return (
      <div>
        <div className="custom-file-img mb-3">
          <Image src={url || imageUrl || defaultImg} fluid />
        </div>
        <div className="input-group mb-3">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input secondary"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={e => this._handleImageChange(e)}
            />
            <label
              className="custom-file-label text-truncate"
              htmlFor="inputGroupFile01"
            >
              {name}
            </label>
          </div>
        </div>
      </div>
    );
  }
}

UploadImage.defaultProps = {
  onChange: () => {}
};

export default UploadImage;
