const styles = {
  container: {
    fontSize: "0.8rem",
    padding: 0
  },
  headerContainer: {
    alignItems: "center",
    marginInline: 0,
    paddingBlock: 10
  },
  headerContainerLeft: {
    alignItems: "center",
    textAlign: "center",
    padding: 0
  },
  headerContentLeftText: {
    fontSize: 22,
    fontWeight: "bold",
    color: "#f4943c"
  },
  headerContainerRight: {
    textAlign: "end"
  },
  headerDate: {
    padding: 12,
    textAlign: "center",
    backgroundColor: "#f4943c",
    color: "white"
  },
  containerForm: {
    color: "#434343",
    padding: "0 6%"
  },
  withoutMargin: {
    margin: 0
  }
};

export default styles;
