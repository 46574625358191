import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Field, getIn } from "formik";
import moment from "moment";
import { MessageSquare, Image, X, Check } from "react-feather";
import { FormikInput, CalculatedField, LinkBtn } from "../../../components";

const QualityRowInput = props => {
  const {
    quality,
    errors,
    touched,
    index,
    values,
    setFieldValue,
    search,
    handleButtonClick
  } = props;
  const [showNotes, setShowNotes] = useState(false);

  const toggleValue = current => {
    return current === "released" ? "rejected" : "released";
  };
  const handleClick = () => {
    setShowNotes(!showNotes);
  };

  const handleReject = () => {
    setFieldValue(
      `project[qualitiesAttributes][${index}][state]`,
      toggleValue(values.quality.state)
    );
    handleButtonClick();
  };

  const handleBlur = () => {
    handleButtonClick();
  };

  return (
    <>
      <tr className={`quality-state-${quality.state}`}>
        <td>
          <Field name={`project[qualitiesAttributes][${index}][boxes]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                onBlur={handleBlur}
                readOnly={quality.state === "rejected"}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </td>
        <td>
          <Field name={`project[qualitiesAttributes][${index}][unitsPerBox]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                onBlur={handleBlur}
                readOnly={quality.state === "rejected"}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </td>
        <td>
          <Field name={`project[qualitiesAttributes][${index}][totalUnits]`}>
            {({ field }) => (
              <CalculatedField
                {...field}
                inputType="number"
                className="asReadOnly"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
                values={values}
                setFieldValue={setFieldValue}
              />
            )}
          </Field>
        </td>
        <td>
          <Field name={`project[qualitiesAttributes][${index}][theme]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                onBlur={handleBlur}
                readOnly={quality.state === "rejected"}
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
          <Field name={`project[qualitiesAttributes][${index}][state]`}>
            {({ field }) => (
              <FormikInput
                {...field}
                type="hidden"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </td>
        <td>{quality.userName}</td>
        <td>{moment(quality.createdAt).format("D/M/YY")}</td>
        <td>
          <Button
            className="m-r-5 btn-old"
            size="sm"
            variant="light"
            onClick={handleClick}
          >
            <MessageSquare width="15" height="15" />
          </Button>
          <Button
            className={`m-r-5 btn-old-${
              quality.state === "rejected" ? "green" : "red"
            }`}
            size="sm"
            variant={quality.state === "rejected" ? "success" : "danger"}
            onClick={handleReject}
          >
            {quality.state === "rejected" ? (
              <Check width="15" height="15" />
            ) : (
              <X width="15" height="15" />
            )}
          </Button>
          {quality.dropZoneFiles.length > 0 && (
            <LinkBtn
              to={`/qualities/${quality.id}${search}`}
              variant="light"
              size="sm"
              className="btn-old"
            >
              <Image width="15" height="15" />
            </LinkBtn>
          )}
        </td>
      </tr>
      {showNotes ? (
        <tr className={`quality-state-${quality.state}`}>
          <td colSpan="7">
            <Field name={`project[qualitiesAttributes][${index}][notes]`}>
              {({ field }) => (
                <FormikInput
                  {...field}
                  onBlur={handleBlur}
                  autoComplete="off"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </td>
        </tr>
      ) : (
        false
      )}
    </>
  );
};

export default QualityRowInput;
