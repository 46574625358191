/* eslint-disable camelcase */
import React from 'react';
import { useDispatch } from 'react-redux';
import { withFormik, Field, Form, getIn } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, Form as BootstrapForm } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { createDropZoneFileRequest, deleteDropZoneFileRequest } from '../../requests/dropzone';
import { FormikInput, Dropzone } from '../../components';
// import image from "../../assets/images/logo.png";
import styles from './styles';

const DeliveryOrderForm = props => {
  const dispatch = useDispatch();

  const { errors, touched, onRequest, setFieldValue, deliveryOrderId, redirect, rut } = props;

  const setState = state => {
    setFieldValue('delivery.state', state);
  };

  const handleDropzoneDrop = (code, documents) => {
    const dropzoneFiles = {
      drop_zone_file: {
        code,
        document_type: 'delivery_review_image',
        documents,
        delivery_order_id: deliveryOrderId
      }
    };

    return createDropZoneFileRequest({
      dispatch,
      formData: true,
      params: dropzoneFiles,
      successCallback: r => r
    });
  };

  const handleDeleteDropZone = deleteFile => {
    const { values } = props;
    setFieldValue(
      'delivery.documentKeys',
      values.delivery.documentKeys.filter(code => code !== deleteFile.code)
    );
    deleteDropZoneFileRequest(deleteFile.id, {
      dispatch,
      params: { dropZoneFile: { documentKey: deleteFile.code } }
    });
  };

  const handleDropzoneUploaded = files => {
    setFieldValue(
      'delivery.documentKeys',
      files.map(file => file.code)
    );
  };
  return (
    <>
      <Row style={styles.headerContainer}>
        <Col xs={{ span: 2 }} style={styles.headerContainerLeft}>
          {!!rut && (
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={() => redirect()}
              className="clickable"
              style={{
                marginInline: 10,
                width: 20,
                height: 20,
                color: '#f4943c'
              }}
            />
          )}
        </Col>

        <Col style={styles.headerContainerLeft}>
          <h4 style={styles.headerContentLeftText}>Formulario Entregas</h4>
        </Col>
        <Col xs={{ span: 2 }} style={styles.headerContainerRight}>
          {/* <FontAwesomeIcon
            icon={faArrowLeft}
            onClick={() => console.log("Cerrar Sesión")}
            className="clickable"
            style={{
              marginInline: 10,
              width: 20,
              height: 20,
              color: "#f4943c"
            }}
          /> */}
        </Col>
      </Row>
      <Form style={styles.containerForm}>
        {!rut && (
          <BootstrapForm.Row>
            <Field name="delivery[rut]">
              {({ field }) => (
                <FormikInput
                  {...field}
                  abbr
                  label="Rut"
                  error={getIn(errors, field.name)}
                  touched={getIn(touched, field.name)}
                />
              )}
            </Field>
          </BootstrapForm.Row>
        )}
        <BootstrapForm.Row>
          <Field name="delivery[document]">
            {({ field }) => (
              <FormikInput
                {...field}
                abbr
                inputType="number"
                label="N° Documento"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </BootstrapForm.Row>
        <p>Imagenes</p>
        <BootstrapForm.Row>
          <div className="form-group col">
            <Dropzone
              onDrop={handleDropzoneDrop}
              onDropUploaded={handleDropzoneUploaded}
              onDelete={handleDeleteDropZone}
            />
          </div>
        </BootstrapForm.Row>
        <BootstrapForm.Row>
          <Field name="delivery[comments]">
            {({ field }) => (
              <FormikInput
                {...field}
                inputAs="textarea"
                label="Comentarios"
                error={getIn(errors, field.name)}
                touched={getIn(touched, field.name)}
              />
            )}
          </Field>
        </BootstrapForm.Row>
        <Row className="d-flex justify-content-end mb-3">
          <Col>
            <Button
              disabled={onRequest}
              type="submit"
              variant="na-red"
              block
              onClick={() => {
                setState(false);
              }}
            >
              Fracaso
            </Button>
          </Col>
          <Col>
            <Button
              disabled={onRequest}
              type="submit"
              variant="success"
              block
              onClick={() => {
                setState(true);
              }}
            >
              Éxito
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const setInitialValues = props => {
  const { rut } = props;

  return {
    delivery: {
      rut,
      document: '',
      comments: '',
      state: null,
      documentKeys: null
    }
  };
};

const validationSchema = Yup.object().shape({
  delivery: Yup.object().shape({
    rut: Yup.string().required('Requerido'),
    document: Yup.number().required('Requerido'),
    comments: Yup.string(),
    documentKeys: Yup.array().of(Yup.string()),
    state: Yup.boolean()
  })
});

const handleSubmit = (values, { props }) => {
  const { formRequest } = props;
  formRequest(values);
};

export default withFormik({
  mapPropsToValues: props => setInitialValues(props),
  validationSchema,
  handleSubmit,
  enableReinitialize: true,
  validateOnMount: props => props.action !== 'new'
})(DeliveryOrderForm);
