import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import qs from 'qs';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';

import { LoadSpinner, Slides } from '../../components';
import { genericErrorMessage } from '../../services/apiService';
import { findOrCreateDeliveryOrderRequest, updateDeliveryOrder } from '../../requests/deliveryOrders';
import { formatMessage } from '../../i18n/language';
import { fallbackTextProp, isNumeric } from '../../services/utils';
import { getStatus, getBaseRef } from './status';

const DeliveryRequestShow = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [onRequest, setOnRequest] = useState(false);
  const [deliveryRequest, setDeliveryRequest] = useState({});
  const {
    match: {
      params: { id: deliveryRequestId }
    },
    location: { search }
  } = props;

  const { jwt, fjwt } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  const {
    clientName,
    clientPhone,
    destinationAddress,
    description: comments,
    dropZoneFiles = [],
    endTime,
    externalId,
    documentNumber,
    status: deliveryStatus
  } = deliveryRequest;
  const status = deliveryStatus || 0;
  const executionDate = status === 2 || status === 4 ? moment(endTime).format('DD/MM/YYYY HH:mm') : '';

  const displayReference = () => {
    if (!documentNumber) {
      return '';
    }
    if (isNumeric(documentNumber)) {
      return documentNumber;
    }
    return getBaseRef(documentNumber);
  };

  const handleSuccessGetDeliveryRequest = response => {
    setOnRequest(false);
    const responseDeliveryRequest = camelCaseRecursive(response.data);
    setDeliveryRequest(responseDeliveryRequest);
  };

  const handleGetDeliveryRequest = () => {
    setOnRequest(true);
    findOrCreateDeliveryOrderRequest(deliveryRequestId, {
      dispatch,
      formData: true,
      successCallback: handleSuccessGetDeliveryRequest,
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const handleStartOrder = deliveryOrderId => {
    setOnRequest(true);
    updateDeliveryOrder(deliveryOrderId, {
      dispatch,
      params: snakeCaseKeys({ delivery: { status: 1 } }),
      formData: true,
      successCallback: () => {
        setOnRequest(false);
        handleGetDeliveryRequest();
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const handleFinishOrder = deliveryOrderId => {
    history.push(
      `/delivery_requests/${deliveryOrderId}/edit?jwt=${encodeURIComponent(jwt)}&fjwt=${encodeURIComponent(fjwt)}`
    );
  };

  const drawActionButton = () => {
    const isPendingOrInProcess = status === 0 || status === 1;
    const statusMessageId = status === 0 ? 'delivery.start' : 'delivery.finalize';
    const buttonColor = status === 0 ? 'success' : 'primary';
    const buttonHandler = status === 0 ? handleStartOrder : handleFinishOrder;
    return isPendingOrInProcess ? (
      <Button variant={buttonColor} className="mt-3 col-12" onClick={() => buttonHandler(externalId)}>
        {formatMessage({ id: statusMessageId })}
      </Button>
    ) : null;
  };

  useEffect(() => {
    handleGetDeliveryRequest();
  }, []);

  return onRequest ? (
    <Col md={12} className="card-form">
      <LoadSpinner />
    </Col>
  ) : (
    <Col md={12} className="card-form">
      <Row className="mt-4">
        <Col md={6}>
          <Row>
            <Col>
              <p className="subtitle1 mb-0">
                {formatMessage({ id: 'delivery.state' })}:{' '}
                <span className={`btn-status col-12 paragraph1 ml-1 ${getStatus(status)}`}>
                  {formatMessage({ id: `delivery.${getStatus(status)}` })}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={6}>
              <p className="subtitle1 mb-0">{formatMessage({ id: 'delivery.address' })}</p>
              <p className="paragraph1">{fallbackTextProp(destinationAddress, formatMessage({ id: 'unavailable' }))}</p>
            </Col>
            <Col md={6}>
              <p className="subtitle1 mb-0">{formatMessage({ id: 'delivery.client' })}</p>
              <p className="paragraph1">{fallbackTextProp(clientName, formatMessage({ id: 'unavailable' }))}</p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={6}>
              <p className="subtitle1 mb-0">{formatMessage({ id: 'delivery.cel' })}</p>
              <p className="paragraph1">{fallbackTextProp(clientPhone, formatMessage({ id: 'unavailable' }))}</p>
            </Col>
            <Col md={6}>
              <p className="subtitle1 mb-0">{formatMessage({ id: 'delivery.executed' })}</p>
              <p className="paragraph1">{executionDate}</p>
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <Col>
              <p className="subtitle1 mb-0">{formatMessage({ id: 'delivery.references' })}</p>
              <p className="paragraph1">{fallbackTextProp(displayReference(), formatMessage({ id: 'unavailable' }))}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mt-3 mb-4">
              <p className="subtitle1">{formatMessage({ id: 'delivery.images' })}</p>
              <Slides items={dropZoneFiles} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="subtitle1">{formatMessage({ id: 'delivery.comments' })}</p>
              <p className="paragraph1">{comments}</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4 justify-content-md-center">
        <Col md={6}>{drawActionButton()}</Col>
      </Row>
    </Col>
  );
};

export default DeliveryRequestShow;
