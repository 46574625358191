import React, { Component } from 'react';
import { connect } from 'react-redux';
import camelCaseRecursive from 'camelcase-keys-recursive';
import { Row, Col } from 'react-bootstrap';
import { BasicCard, RemoteSelect } from '../../components';
import showMeRequest from '../../requests/auth';
import {
  debounceIndexSlidesRequest,
  indexSlidesRequest
} from '../../requests/slides';

class Profile extends Component {
  state = {
    user: {
      avatar: []
    },
    slide: {}
  };

  componentDidMount() {
    this.dispatchGetMe();
  }

  dispatchGetMe = () => {
    const { dispatch } = this.props;
    showMeRequest({
      dispatch,
      successCallback: response => {
        if (response.data.logged_in) {
          this.setState({ user: camelCaseRecursive(response.data.user_info) });
        }
      }
    });
  };

  dispatchFetchData = () => {
    const { dispatch } = this.props;
    indexSlidesRequest({
      dispatch,
      params: { sort_column: 'caption', display_length: 10 },
      successCallback: data => {
        this.resultFetchData(data);
      }
    });
  };

  fetchData = (inputValue, callback) => {
    const { dispatch } = this.props;
    debounceIndexSlidesRequest({
      dispatch,
      params: {
        caption: inputValue,
        sort_column: 'caption',
        display_length: 10
      },
      successCallback: data => {
        callback(this.resultFetchData(data));
      }
    });
  };

  resultFetchData = (response) => {
    const result = response.data.data;
    const tempArray = result.map(element => {
      return {
        label: element.caption,
        value: element.id,
        ...element
      };
    });
    return tempArray;
  };

  onSearchChange = selectedOption => {
    // When it is multiple, you receive an array
    if (selectedOption) {
      this.setState({ slide: selectedOption });
    } else {
      this.setState({ slide: {} });
    }
  };

  render() {
    const { user, slide } = this.state;
    const avatarUrl = user.avatar.length ? user.avatar[0].url : '';
    let active = '';
    if (slide.id) {
      active = slide.active ? 'Sí' : 'No';
    }

    return (
      <Row>
        <Col md={5}>
          <BasicCard
            title="PERFIL"
            src={avatarUrl}
            direction="top"
            text={
              <>
                ID: {user.id}
                <br />
                ROLE: {user.role}
                <br />
                EMAIL: {user.email}
              </>
            }
            variantBtn="primary"
            titleBtn="Editar perfil"
            path="/profile/edit"
          />
        </Col>
        <Col md={4}>
          <BasicCard
            title="BUSCAR SLIDE"
            subtitle={
              <RemoteSelect
                request={this.fetchData}
                onChange={e => this.onSearchChange(e)}
                isClearable
              />
            }
            text={
              <>
                ID: {slide.id}
                <br />
                Activo: {active}
                <br />
                Posición: {slide.position}
              </>
            }
          />
        </Col>
      </Row>
    );
  }
}

export default connect()(Profile);
