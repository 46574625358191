import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import Geocode from "react-geocode";
import rootSaga from "../sagas";
import createRootReducer from "../reducers";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API);
Geocode.setLanguage("es");
Geocode.setRegion("cl");
Geocode.enableDebug();

export const geoCode = Geocode;

export const history = createBrowserHistory();

const sagaMW = createSagaMiddleware();
const routerMW = routerMiddleware(history);
const middleware = [sagaMW, routerMW];

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createRootReducer(history),
  composeEnhancer(applyMiddleware(...middleware))
);

sagaMW.run(rootSaga);

export default store;
