import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Spinner } from 'react-bootstrap';
import camelCaseRecursive from 'camelcase-keys-recursive';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formatMessage } from '../../i18n/language';
import { LoadSpinner, GoogleMapComponent } from '../../components';
import { genericErrorMessage } from '../../services/apiService';
import { indexDeliveryOrders } from '../../requests/deliveryOrders';

import icons from '../../assets/images/iconsMap';

const DeliveryRequestMap = () => {
  moment.lang(localStorage.getItem('language'));
  const dispatch = useDispatch();
  const [onRequest, setOnRequest] = useState(false);
  const [allDeliveryOrders, setAllDeliveryOrders] = useState(false);
  const [dateState, setDateState] = useState(new Date());
  const [initialCenterMap, setInitialCenterMap] = useState(false);
  const [driversColor, setDriversColor] = useState(false);
  const [availableUsers, setAvailableUsers] = useState({});
  const [orderFocus, setOrderFocus] = useState(false);
  const [selectedUser, setSelectedUser] = useState('all');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [dateType, setDateType] = useState('day');
  const [dateRange, setDateRange] = useState({});
  const dayToggleButton = useRef(null);
  const weekToggleButton = useRef(null);
  const orderDivs = useRef({});
  const [activeCoords, setActiveCoords] = useState(null);

  const handleGetAllDeliveryRequests = () => {
    setOnRequest(true);
    let params = { startTime: moment(dateState).format('DD/MM/YYYY'), forMaps: true };
    if (dateType === 'week') {
      params = {
        startTime: moment(dateRange.st).format('DD/MM/YYYY'),
        endTime: moment(dateRange.et).format('DD/MM/YYYY'),
        forMaps: true
      };
    }

    indexDeliveryOrders({
      dispatch,
      params,
      formData: true,
      successCallback: response => {
        setOnRequest(false);

        const { driversColor: initdriversColor, deliveryOrders, users } = camelCaseRecursive(response.data);
        let lat;
        let lng;
        if (deliveryOrders.length) {
          lat = deliveryOrders[0].destinationLat;
          lng = deliveryOrders[0].destinationLng;
        }
        setDriversColor(initdriversColor);
        setInitialCenterMap({
          lat: lat || 37.6,
          lng: lng || -95.665
        });
        setAllDeliveryOrders(deliveryOrders);
        setAvailableUsers(users);
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const markersDeliveryRequest = () => {
    const orders = filteredOrders;
    if (!orders || !orders.length) {
      return [{ lat: 1, lng: 1, icon: '' }]; // map crash if there are no markers
    }

    const deliveryRequestsActive = orders.filter(d => d.destinationLat);

    const users = Array.from(new Set(driversColor.map(driver => driver.userId))).map(userId => {
      const driverColor = driversColor.find(driver => driver.userId === userId);
      return { userId, icon: icons[driverColor.orderColor] };
    });

    return deliveryRequestsActive.map(dr => {
      const infoComponenet = !dr.init ? (
        <>
          <p>
            {dr.project?.externalId && dr.project?.externalId} - {dr.project?.name && dr.project?.name}{' '}
          </p>
          <p>{dr.destinationAddress}</p>
          <p>{moment(dr.endTime).calendar()}</p>
        </>
      ) : (
        <>
          <p>Inicio</p>
        </>
      );
      let icon = '';
      let pin;
      let pinFocus;
      let check;
      const user = users.find(u => u.userId === dr.userId);
      if (user) {
        ({ pin, pinFocus, check } = user.icon);
      } else {
        [{ pin, pinFocus, check }] = icons;
      }

      if (orderFocus === dr.id) {
        icon = pinFocus;
      } else if (dr.status === 2) {
        icon = check;
      } else {
        icon = pin;
      }

      return {
        lat: dr.destinationLat,
        lng: dr.destinationLng,
        info: infoComponenet,
        icon,
        status: dr.status,
        isFex: dr.isFex,
        userId: dr.userId,
        endTime: dr.endTime,
        color: dr.userColor
      };
    });
  };

  const coordsPolyLines = markers => {
    // external pins only
    const externalMarkers = markers.filter(marker => !marker.isFex);
    // get unique users Id
    const usersId = Array.from(new Set(externalMarkers.map(marker => marker.userId)));
    // Markers for user
    const userMarkers = usersId.map(id => {
      const filterMarker = externalMarkers.filter(marker => marker.userId === id);
      // order fot end time
      return filterMarker.sort((a, b) => new Date(a.endTime) - new Date(b.endTime));
    });
    const PolylineCoords = [];
    userMarkers.forEach(markersUser => {
      markersUser.forEach((marker, i) => {
        if (markersUser[i + 1] && marker.status !== 0 && markersUser[i + 1].status === 0) {
          PolylineCoords.push({
            strokeColor: marker.color,
            coords: [
              { lat: marker.lat, lng: marker.lng },
              { lat: markersUser[i + 1].lat, lng: markersUser[i + 1].lng }
            ]
          });
        }
      });
    });
    return PolylineCoords;
  };

  const getInitials = name => {
    return name
      .split(' ')
      .map(item => item[0])
      .join('')
      .toUpperCase();
  };

  const handleOrderFocus = (_, id) => {
    // eslint-disable-next-line no-shadow, no-unused-vars
    Object.entries(orderDivs.current).forEach(([_, v]) => {
      if (v) v.classList.remove('active');
    });
    if (!orderFocus || orderFocus !== id) {
      setOrderFocus(id);
      const order = allDeliveryOrders.find(item => item.id === id);
      setActiveCoords({
        lat: order.destinationLat,
        lng: order.destinationLng
      });
      orderDivs.current[id].classList.add('active');
      return;
    }
    setOrderFocus(false);
  };

  const deliveryOrder = order => {
    return (
      <Row
        key={order.id}
        style={{ border: '1px solid', borderRadius: 20, cursor: 'pointer' }}
        className="p-2 mb-2 delivery-order"
        onClick={e => handleOrderFocus(e, order.id)}
        ref={elem => {
          orderDivs.current[order.id] = elem;
        }}
      >
        <Col>
          <Row style={{ fontSize: 12 }}>
            <Col xs={3} className="p-0">
              <Col className="p-0">
                <div
                  style={{ background: order.userColor, width: 38, height: 38, borderRadius: 100 }}
                  className="d-flex align-items-center justify-content-center font-weight-bold p-0"
                >
                  {getInitials(order.userName)}
                </div>
              </Col>
            </Col>
            <Col>
              <Row>{order.userName}</Row>
              <Row>
                {order.project
                  ? `${order.project.externalId} - ${order.project.name}`
                  : formatMessage({ id: 'delivery.unavailableProject' })}
              </Row>
              <Row style={{ fontWeight: 500 }}>{order.destinationAddress}</Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const renderOrders = () => {
    const groupedOrders = {};
    filteredOrders.forEach(order => {
      const date = moment(new Date(order.startTime)).format('YYYY/MM/DD');
      if (!groupedOrders[date]) groupedOrders[date] = [];
      groupedOrders[date].push(order);
    });

    const renderGroupedOrders = [];
    Object.entries(groupedOrders).forEach(([date, orders]) => {
      renderGroupedOrders.push(
        <Row className="mb-1" key={date}>
          {moment(new Date(date)).format('D MMMM')}
        </Row>
      );
      orders.forEach(order => {
        renderGroupedOrders.push(deliveryOrder(order));
      });
    });
    return renderGroupedOrders;
  };

  const renderAvalableUsers = Object.entries(availableUsers).map(([id, name]) => (
    <option key={id} value={id}>
      {name}
    </option>
  ));

  const handleSelectedUser = e => {
    setSelectedUser(e.target.value);
  };

  const handleDateToday = () => {
    setDateState(new Date());
  };

  const GoToDateButton = forwardRef(({ onClick }, ref) => (
    <button type="button" className="fc-button clickable mr-2" onClick={onClick} ref={ref}>
      {formatMessage({ id: 'delivery.goTo' })}
    </button>
  ));

  const handleToggleDates = (e, type) => {
    setOrderFocus(false);
    weekToggleButton.current.classList.remove('active');
    dayToggleButton.current.classList.remove('active');
    e.target.classList.add('active');
    setDateType(type);
    const startOfWeek = new Date(Date.parse(moment(dateState).startOf('week')));
    const endOfWeek = new Date(Date.parse(moment(dateState).endOf('week')));
    setDateRange({ st: startOfWeek, et: endOfWeek });
  };

  const handleNextDate = () => {
    setOrderFocus(false);
    setSelectedUser('all');
    let nextDate;
    if (dateType === 'day') {
      nextDate = new Date(moment(dateState).add(1, 'day'));
      setDateState(nextDate);
    } else {
      nextDate = new Date(moment(dateState).add(7, 'day'));
      setDateState(new Date(moment(dateState).add(7, 'day')));
    }
    const startOfWeek = new Date(Date.parse(moment(nextDate).startOf('week')));
    const endOfWeek = new Date(Date.parse(moment(nextDate).endOf('week')));
    setDateRange({ st: startOfWeek, et: endOfWeek });
  };

  const handlePreviusDate = () => {
    setOrderFocus(false);
    setSelectedUser('all');
    let previusDate;
    if (dateType === 'day') {
      previusDate = new Date(moment(dateState).subtract(1, 'day'));
      setDateState(previusDate);
    } else {
      previusDate = new Date(moment(dateState).subtract(7, 'day'));
      setDateState(previusDate);
    }
    const startOfWeek = new Date(Date.parse(moment(previusDate).startOf('week')));
    const endOfWeek = new Date(Date.parse(moment(previusDate).endOf('week')));
    setDateRange({ st: startOfWeek, et: endOfWeek });
  };

  useEffect(() => {
    if (selectedUser !== 'all') {
      setFilteredOrders(allDeliveryOrders.filter(o => o.userId === +selectedUser));
      return;
    }
    setFilteredOrders(allDeliveryOrders);
  }, [allDeliveryOrders, selectedUser]);

  useEffect(() => {
    setDateState(new Date());
  }, []);

  useEffect(() => {
    handleGetAllDeliveryRequests();
  }, [dateState, dateType, dateRange]);

  return !filteredOrders && onRequest ? (
    <LoadSpinner />
  ) : (
    <>
      <Row>
        <Col className="text-center">
          <p className="mb-0" style={{ fontSize: '2rem', color: '#2743B2', fontWeight: '500' }}>
            {dateType === 'day' ? (
              <>
                <span className="capitalize">{moment(dateState).format('dddd, ')}</span>
                <span className="capitalize">{moment(dateState).format('D MMMM, ')}</span>
                <span>{moment(dateState).format('YYYY')}</span>
              </>
            ) : (
              <span>
                {moment(dateRange.st).format('D')}—{moment(dateRange.et).format('D MMMM YYYY')}
              </span>
            )}
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="d-flex" md={6}>
          <div>
            <button type="button" className="fc-button mr-2 clickable" onClick={handleDateToday}>
              {formatMessage({ id: 'delivery.today' })}
            </button>
          </div>
          <DatePicker selected={dateState} onChange={date => setDateState(date)} customInput={<GoToDateButton />} />
          <div>
            <select
              id="user-select"
              className="fc-button"
              value={selectedUser}
              onChange={handleSelectedUser}
              style={{ width: 150 }}
            >
              <option value="all">{formatMessage({ id: 'delivery.all' })}</option>
              {renderAvalableUsers}
            </select>
          </div>
        </Col>
        <Col className="d-flex justify-content-end mt-1 mt-md-0" md={6}>
          <button
            id="week-button"
            ref={weekToggleButton}
            type="button"
            className="btn btn-outline-primary"
            onClick={e => handleToggleDates(e, 'week')}
          >
            {formatMessage({ id: 'delivery.week' })}
          </button>
          <button
            id="day-button"
            ref={dayToggleButton}
            type="button"
            className="btn btn-outline-primary active ml-4"
            onClick={e => handleToggleDates(e, 'day')}
          >
            {formatMessage({ id: 'delivery.day' })}
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row
            className="flex-nowrap"
            style={{
              padding: 10,
              background: '#eceef5',
              margin: 0,
              border: '1px solid',
              borderColor: '#dfe1ed',
              borderRadius: '10px 10px 0 0'
            }}
          >
            <Col xs={9}>{formatMessage({ id: 'delivery.scheduledRoutes' })}</Col>
            <Col xs={1}>
              <FontAwesomeIcon icon={faChevronLeft} onClick={handlePreviusDate} className="clickable" />
            </Col>
            <Col xs={1}>
              <FontAwesomeIcon icon={faChevronRight} onClick={handleNextDate} className="clickable" />
            </Col>
          </Row>
          <Row
            className="flex-column"
            style={{
              padding: 10,
              margin: 0,
              border: '1px solid',
              borderColor: '#dfe1ed',
              borderRadius: '0 0 10px 10px',
              maxHeight: 455,
              overflow: 'auto'
            }}
          >
            <Col>
              {(() => {
                if (onRequest) {
                  return <Spinner animation="border" role="status" variant="primary" />;
                }
                if (filteredOrders.length) {
                  return renderOrders();
                }
                return <div>{formatMessage({ id: 'delivery.noOrders' })}</div>;
              })()}
            </Col>
          </Row>
        </Col>
        <Col lg={8} className="mt-3 mt-lg-0">
          <Row style={{ textAlign: '-webkit-center', margin: 0 }}>
            <Col style={{ padding: 0 }}>
              {filteredOrders && (
                <GoogleMapComponent
                  isMarkerShown
                  markers={markersDeliveryRequest()}
                  center={activeCoords || initialCenterMap}
                  zoom={12}
                  lineCoords={coordsPolyLines(markersDeliveryRequest())}
                  isFocus={orderFocus}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DeliveryRequestMap;
