import React, { useState, useEffect, forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import camelCaseRecursive from 'camelcase-keys-recursive';
import snakeCaseKeys from 'snakecase-keys';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import 'moment/locale/es';
import moment from 'moment';
import qs from 'qs';
import { geolocated } from 'react-geolocated';
import jwtDecode from 'jwt-decode';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import DeliveryRequestListItem from './DeliveryRequestListItem';
import { LoadSpinner } from '../../components';
import { indexDeliveryOrders, updateDeliveryOrder } from '../../requests/deliveryOrders';
import { genericErrorMessage } from '../../services/apiService';
import { formatMessage, getCurrentLanguage } from '../../i18n/language';
import './styles.scss';

const DeliveryRequestIndex = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [dateState, setDateState] = useState(new Date());
  const [onRequest, setOnRequest] = useState(false);
  moment.locale(getCurrentLanguage());

  const {
    location: { search }
  } = props;

  const { jwt, fjwt } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  const {
    user: { id: userDriverId }
  } = jwtDecode(jwt);

  const handleSelectOrder = deliveryOrderId => {
    history.push(
      `/delivery_requests/${deliveryOrderId}?jwt=${encodeURIComponent(jwt)}&fjwt=${encodeURIComponent(fjwt)}`
    );
  };

  const handleFinishOrder = deliveryOrderId => {
    history.push(
      `/delivery_requests/${deliveryOrderId}/edit?jwt=${encodeURIComponent(jwt)}&fjwt=${encodeURIComponent(fjwt)}`
    );
  };

  const handleIndexDeliveryRequest = () => {
    setOnRequest(true);
    indexDeliveryOrders({
      dispatch,
      formData: true,
      params: {
        userDriverId,
        isExternal: false,
        startTime: moment(dateState).format('DD/MM/YYYY')
      },
      successCallback: response => {
        setOnRequest(false);
        let data = camelCaseRecursive(response.data).deliveryOrders.filter(delivery => delivery.startTime != null);
        data = data.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        setDeliveryOrders(data);
      }
    });
  };

  const handleStartOrder = deliveryOrderId => {
    setOnRequest(true);
    updateDeliveryOrder(deliveryOrderId, {
      dispatch,
      params: snakeCaseKeys({ delivery: { status: 1 } }),
      formData: true,
      successCallback: () => {
        setOnRequest(false);
        handleFinishOrder(deliveryOrderId);
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const handlePauseOrder = deliveryOrderId => {
    setOnRequest(true);
    updateDeliveryOrder(deliveryOrderId, {
      dispatch,
      params: snakeCaseKeys({ delivery: { status: 0 } }),
      formData: true,
      successCallback: () => {
        setOnRequest(false);
        handleIndexDeliveryRequest();
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const handleDateToday = () => {
    setDateState(new Date());
  };

  const handleDateChange = amount => {
    setDateState(new Date(dateState.getTime() + amount * (3600 * 1000 * 24)));
  };

  const renderDeliveryOrders = () => {
    return deliveryOrders.length > 0 ? (
      deliveryOrders.map(deliveryOrder => (
        <DeliveryRequestListItem
          key={deliveryOrder.id}
          deliveryOrder={deliveryOrder}
          handleStart={handleStartOrder}
          handleFinish={handleFinishOrder}
          handlePause={handlePauseOrder}
          handleSelectOrder={handleSelectOrder}
        />
      ))
    ) : (
      <Col xs="12">
        <p className="paragraph1" style={{ textAlign: 'center', marginTop: '40px' }}>
          {formatMessage({ id: 'delivery.noTasks' })}
        </p>
      </Col>
    );
  };

  useEffect(() => {
    setDateState(new Date());
  }, []);

  useEffect(() => {
    handleIndexDeliveryRequest();
  }, [dateState]);

  const GoToDateButton = forwardRef(({ onClick }, ref) => (
    <button type="button" className="fc-button clickable" onClick={onClick} ref={ref}>
      {formatMessage({ id: 'delivery.goTo' })}
    </button>
  ));

  return (
    <>
      <Col md={12} className="card-form delivery-request-index">
        <Row>
          <Col xs={7}>
            <Row>
              <h5 className="table-text-title">{formatMessage({ id: 'delivery.myTasks' })}</h5>
            </Row>
          </Col>
        </Row>
        <Row className="mt-3">
          <button type="button" className="fc-button mr-2 clickable" onClick={handleDateToday}>
            {formatMessage({ id: 'delivery.today' })}
          </button>
          <DatePicker selected={dateState} onChange={date => setDateState(date)} customInput={<GoToDateButton />} />
        </Row>
        <Row className="mt-3 task-move-cal">
          <Col xs={3}>
            <FontAwesomeIcon icon={faChevronCircleLeft} className="clickable" onClick={() => handleDateChange(-1)} />
          </Col>
          <Col xs={6} className="text-center">
            {moment(dateState).format('L')}
          </Col>
          <Col xs={3} className="text-right">
            <FontAwesomeIcon icon={faChevronCircleRight} className="clickable" onClick={() => handleDateChange(1)} />
          </Col>
        </Row>
        <Row className="inner-card-container mt-3">{onRequest ? <LoadSpinner /> : renderDeliveryOrders()}</Row>
      </Col>
    </>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(DeliveryRequestIndex);
