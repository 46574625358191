import { defineMessages } from 'react-intl';

const translations = defineMessages({
  'project.index.title': {
    id: 'project.index.title',
    defaultMessage: 'Index Proyectos'
  },
  'project.noName': {
    id: 'project.noName',
    defaultMessage: 'Sin Nombre'
  },
  'project.noNameAbbreviated': {
    id: 'project.noNameAbbreviated',
    defaultMessage: 'SN'
  },
  'project.qualities.creationSuccess': {
    id: 'project.qualities.creationSuccess',
    defaultMessage: 'Calidad creada'
  },
  'project.delivery.creationSuccess': {
    id: 'project.delivery.creationSuccess',
    defaultMessage: 'Flete Solicitado'
  },
  'project.pricing.delivery.creationSuccess': {
    id: 'project.pricing.delivery.creationSuccess',
    defaultMessage: 'Cotización realizada'
  },
  'delivery.address.origin': {
    id: 'delivery.address.origin',
    defaultMessage: 'Dirección de Origen '
  },
  'delivery.address.destination': {
    id: 'delivery.address.destination',
    defaultMessage: 'Dirección de Destino '
  },
  'delivery.client.name': {
    id: 'delivery.client.name',
    defaultMessage: 'Nombre Cliente'
  },
  'delivery.client.phone': {
    id: 'delivery.client.phone',
    defaultMessage: 'Teléfono Cliente'
  },
  'delivery.description': {
    id: 'delivery.description',
    defaultMessage: 'Descripción de Carga'
  },
  'delivery.roundtrip': {
    id: 'delivery.roundtrip',
    defaultMessage: 'Viaje ida y vuelta'
  },
  'delivery.vehicle': {
    id: 'delivery.vehicle',
    defaultMessage: 'Tipo de vehículo'
  },
  'delivery.quote': {
    id: 'delivery.quote',
    defaultMessage: 'Cotizar'
  },
  'delivery.distance': {
    id: 'delivery.distance',
    defaultMessage: 'Distancia'
  },
  'delivery.project': {
    id: 'delivery.project',
    defaultMessage: 'Proyecto'
  },
  'delivery.cancelService': {
    id: 'delivery.cancelService',
    defaultMessage: 'Cancelar Servicio'
  },
  'delivery.myTasks': {
    id: 'delivery.myTasks',
    defaultMessage: 'Mis Tareas'
  },
  'delivery.today': {
    id: 'delivery.today',
    defaultMessage: 'Hoy'
  },
  'delivery.goTo': {
    id: 'delivery.goTo',
    defaultMessage: 'Ir a...'
  },
  'delivery.state': {
    id: 'delivery.state',
    defaultMessage: 'Estado'
  },
  'delivery.inProcess': {
    id: 'delivery.inProcess',
    defaultMessage: 'En Proceso'
  },
  'delivery.phone': {
    id: 'delivery.phone',
    defaultMessage: 'Teléfono'
  },
  'delivery.finalize': {
    id: 'delivery.finalize',
    defaultMessage: 'Finalizar'
  },
  'delivery.finalized': {
    id: 'delivery.finalized',
    defaultMessage: 'Finalizado'
  },
  'delivery.pending': {
    id: 'delivery.pending',
    defaultMessage: 'Pendiente'
  },
  'delivery.pendings': {
    id: 'delivery.pendings',
    defaultMessage: 'Pendientes'
  },
  'delivery.references': {
    id: 'delivery.references',
    defaultMessage: 'Referencias'
  },
  'delivery.pendingPlaceholder': {
    id: 'delivery.pendingPlaceholder',
    defaultMessage: 'Escoge un pendiente'
  },
  'delivery.start': {
    id: 'delivery.start',
    defaultMessage: 'Comenzar'
  },
  'delivery.reactivate': {
    id: 'delivery.reactivate',
    defaultMessage: 'Reactivar'
  },
  'delivery.address': {
    id: 'delivery.address',
    defaultMessage: 'Dirección'
  },
  'delivery.client': {
    id: 'delivery.client',
    defaultMessage: 'Cliente'
  },
  'delivery.provider': {
    id: 'delivery.provider',
    defaultMessage: 'Proveedor'
  },
  'delivery.cel': {
    id: 'delivery.cel',
    defaultMessage: 'Cel'
  },
  'delivery.executed': {
    id: 'delivery.executed',
    defaultMessage: 'Ejecución'
  },
  'delivery.images': {
    id: 'delivery.images',
    defaultMessage: 'Imagenes'
  },
  'delivery.comments': {
    id: 'delivery.comments',
    defaultMessage: 'Comentarios'
  },
  'delivery.commentPlaceholder': {
    id: 'delivery.commentPlaceholder',
    defaultMessage: 'Esrcribe cualquier tipo de información adicional al envio...'
  },
  'delivery.failed': {
    id: 'delivery.failed',
    defaultMessage: 'Fallido'
  },
  'delivery.success': {
    id: 'delivery.success',
    defaultMessage: 'Exitoso'
  },
  'delivery.noTasks': {
    id: 'delivery.noTasks',
    defaultMessage: 'No tienes ninguna tarea asignada'
  },
  'delivery.noReference': {
    id: 'delivery.noReference',
    defaultMessage: 'No Generar - Finalizar Visita'
  },
  'delivery.noReferenceAndFinalized': {
    id: 'delivery.noReferenceAndFinalized',
    defaultMessage: 'Sin Referencia y Finalizado'
  },
  'delivery.noReferenceAndFinalize': {
    id: 'delivery.noReferenceAndFinalize',
    defaultMessage: 'Generar - Finalizar Orden'
  },
  'delivery.associate': {
    id: 'delivery.associate',
    defaultMessage: 'Asociar'
  },
  'delivery.pause': {
    id: 'delivery.pause',
    defaultMessage: 'Pausar'
  },
  'delivery.all': {
    id: 'delivery.all',
    defaultMessage: 'Todos'
  },
  'delivery.day': {
    id: 'delivery.day',
    defaultMessage: 'Día'
  },
  'delivery.week': {
    id: 'delivery.week',
    defaultMessage: 'Semana'
  },
  'delivery.scheduledRoutes': {
    id: 'delivery.scheduledRoutes',
    defaultMessage: 'Rutas agendadas'
  },
  'delivery.noOrders': {
    id: 'delivery.noOrders',
    defaultMessage: 'No hay ordenes agendadas'
  },
  'delivery.unavailableProject': {
    id: 'delivery.unavailableProject',
    defaultMessage: 'No posee un proyecto asociado'
  },
  'delivery.tooltip.generate': {
    id: 'delivery.tooltip.generate',
    defaultMessage: 'Generar: '
  },
  'delivery.tooltip.doNotGenerate': {
    id: 'delivery.tooltip.doNotGenerate',
    defaultMessage: 'No generar: '
  },
  'delivery.tooltip.associate': {
    id: 'delivery.tooltip.associate',
    defaultMessage: 'Asociar: '
  },
  'delivery.tooltip.generateHelp': {
    id: 'delivery.tooltip.generateHelp',
    defaultMessage: 'Cambia estado de la orden a finalizado y genera encuesta postservicio.'
  },
  'delivery.tooltip.doNotGenerateHelp': {
    id: 'delivery.tooltip.doNotGenerateHelp',
    defaultMessage: 'Reporta agendamiento y no modifica estado de la orden.'
  },
  'delivery.tooltip.associateHelp': {
    id: 'delivery.tooltip.associateHelp',
    defaultMessage:
      'Asociar con documento de traslado existente. Una vez se registrada cantidad total ídem a orden se genera encuesta postservicio, y estado de pedido queda en finalizado'
  },
  'formik.search': {
    id: 'formik.search',
    defaultMessage: 'Buscar...'
  },
  'formik.loading': {
    id: 'formik.loading',
    defaultMessage: 'Cargando...'
  },
  'formik.requiredField': {
    id: 'formik.requiredField',
    defaultMessage: 'Este campo es obligatorio'
  },
  'formik.noOptions': {
    id: 'formik.noOptions',
    defaultMessage: 'No hay opciones'
  },
  'dropzone.uploadPhotos': {
    id: 'dropzone.uploadPhotos',
    defaultMessage: '+ Subir Fotos'
  },
  'dropzone.reject': {
    id: 'dropzone.reject',
    defaultMessage: 'Archivo no válido para subir.'
  },
  'dropzone.active': {
    id: 'dropzone.active',
    defaultMessage: 'Suelta los archivos aquí para subir...'
  },
  'dropzone.disabled': {
    id: 'dropzone.disabled',
    defaultMessage: 'Alcanzaste la cantidad máxima de archivos.'
  },
  'dropzone.tooLarge': {
    id: 'dropzone.tooLarge',
    defaultMessage: 'El archivo excede el peso máximo permitido'
  },
  unavailable: {
    id: 'unavailable',
    defaultMessage: 'No disponible'
  }
});

export default translations;
