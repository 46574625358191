import React, { useEffect, useRef } from "react";
import { Form, Col } from "react-bootstrap";

const CalculatedField = ({
  placeholder,
  label,
  size,
  margin,
  error,
  touched,
  helpText,
  inputType,
  abbr,
  setFieldValue,
  values,
  name,
  ...props
}) => {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      let val = 0;
      if (values.quality.boxes && values.quality.unitsPerBox) {
        val = values.quality.boxes * values.quality.unitsPerBox;
      }
      setFieldValue(name, val);
    } else {
      isMounted.current = true;
    }
  }, [values.quality.boxes, values.quality.unitsPerBox, name, setFieldValue]);

  return (
    <Form.Group className={margin} as={Col}>
      {label ? (
        <Form.Label>
          {label} {abbr ? <abbr className="text-danger">*</abbr> : false}
        </Form.Label>
      ) : (
        false
      )}
      <Form.Control
        name={name}
        size={size}
        placeholder={placeholder}
        type={inputType}
        className={error && touched ? "is-invalid" : ""}
        {...props}
      />
      {error && touched ? (
        <Form.Text className="text-danger">{error}</Form.Text>
      ) : null}
      {helpText ? (
        <Form.Text className="text-muted">{helpText}</Form.Text>
      ) : null}
    </Form.Group>
  );
};

export default CalculatedField;
