import { formatMessage } from '../../i18n/language';

const deliveryOrderStatus = {
  0: 'pending',
  1: 'inProcess',
  2: 'finalized',
  3: 'blocked',
  4: 'failed'
};

const baseOptions = [
  { value: 'noRefAndFinalize', label: formatMessage({ id: 'delivery.noReferenceAndFinalize' }) },
  { value: 'noRef', label: formatMessage({ id: 'delivery.noReference' }) }
];

const excludingBaseOptions = [{ value: 'noRef', label: formatMessage({ id: 'delivery.noReference' }) }];

const baseOptionsObject = {
  noRefAndFinalize: formatMessage({ id: 'delivery.noReferenceAndFinalized' }),
  noRef: formatMessage({ id: 'delivery.noReference' })
};

const getStatus = status => deliveryOrderStatus[status];

const getBaseRef = ref => baseOptionsObject[ref];

export { baseOptions, excludingBaseOptions, getStatus, getBaseRef };
