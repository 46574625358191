import React from "react";
import { Row, Col } from "react-bootstrap";

const QualitySuccess = () => {
  return (
    <Row>
      <Col md={{ span: 4, offset: 4 }}>
        <h3 className="m-t-30">Calidad ingresada con éxito</h3>
      </Col>
    </Row>
  );
};

export default QualitySuccess;
