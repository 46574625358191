import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { authTypes, requestSignIn } from '../actions/auth';
import API from '../services/api';
import runDefaultSaga, { updateJWT } from './default';

// SIGN IN
const signInRequest = params => API.post('/login', params);
function* signInSuccessCallback(result, response) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    const authorization = response.headers
      .get('Authorization')
      .split('Bearer ')[1];
    yield updateJWT(authorization);
    yield put({ type: authTypes.SIGN_IN_SUCCESS, result, response });
    yield put(push('/home'));
  }
}
function* signInFailureCallback() {
  yield put({
    type: authTypes.SIGN_IN_FAILURE
  });
}
export function* signIn(action) {
  yield runDefaultSaga(
    { request: signInRequest, params: action.params },
    signInSuccessCallback,
    signInFailureCallback
  );
}

// CREATE ACCOUNT
const signUpRequest = params => API.post('/signup', params);
function* signUpSuccessCallback(result, response, params) {
  if (result.errors) {
    throw new Error(result.errors.join('\n'));
  } else {
    yield put({ type: authTypes.SIGN_UP_SUCCESS, result, response });
    const user = params;
    delete user.password_confirmation;
    yield put(requestSignIn(user));
  }
}
function* signUpFailureCallback() {
  yield put({
    type: authTypes.SIGN_UP_FAILURE
  });
}
export function* signUp(action) {
  yield runDefaultSaga(
    { request: signUpRequest, params: action.params },
    signUpSuccessCallback,
    signUpFailureCallback
  );
}

// SIGN OUT
const signOutRequest = () => API.delete('/logout');
function* signOutSuccessCallback(result) {
  if (result.success) {
    yield localStorage.removeItem('jwt');
    yield put({ type: authTypes.SIGN_OUT_SUCCESS });
    yield put(push('/'));
  } else {
    throw new Error(result);
  }
}
function* signOutFailureCallback() {
  yield put({ type: authTypes.SIGN_OUT_FAILURE });
}
export function* signOut() {
  yield runDefaultSaga(
    { request: signOutRequest },
    signOutSuccessCallback,
    signOutFailureCallback
  );
}

// VALIDATE TOKENS
const validateTokenRequest = () => API.get('/me');
function* validateTokensSuccessCallback(result, response) {
  if (result.logged_in) {
    yield put({
      type: authTypes.VALIDATE_TOKEN_SUCCESS,
      result,
      response,
      user: result.user_info
    });
  } else {
    yield put({ type: authTypes.CLEAR_AUTH_INFO });
    yield put(push('/login'));
  }
}
function* validateTokensFailureCallback() {
  yield put({ type: authTypes.CLEAR_AUTH_INFO });
  yield put(push('/login'));
}
function* validateToken() {
  yield runDefaultSaga(
    { request: validateTokenRequest },
    validateTokensSuccessCallback,
    validateTokensFailureCallback
  );
}

// DEFINE authSagas
export default function* authSagas() {
  yield takeEvery(authTypes.SIGN_IN_REQUEST, signIn);
  yield takeEvery(authTypes.SIGN_UP_REQUEST, signUp);
  yield takeEvery(authTypes.SIGN_OUT_REQUEST, signOut);
  yield takeEvery(authTypes.VALIDATE_TOKEN_REQUEST, validateToken);
}
