import React, { Component } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPage } from '../../actions/utils';

class Page extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount = () => {
    const { dispatch, match } = this.props;
    dispatch(getPage(match.params.page));
  };

  loading = () => <Spinner animation="border" variant="primary" />;

  content = () => {
    const {
      utils: {
        page: { body }
      }
    } = this.props;

    if (body) {
      return <div dangerouslySetInnerHTML={{ __html: body }} />;
    }
    return <h4>404</h4>;
  };

  render() {
    const {
      utils: { ongoingRequest }
    } = this.props;
    return (
      <Row className="justify-content-center">
        <Col md={4}>
          {ongoingRequest.getPage ? this.loading() : this.content()}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { utils } = state;
  return {
    utils
  };
};

export default withRouter(connect(mapStateToProps)(Page));
