import React from "react";
import { Switch } from "react-router-dom";
import {
  LoggedLayout,
  NonLoggedLayout,
  PublicLayout,
  TwistLayout,
  TwistLayoutContainer
} from "./layouts";
import {
  Login,
  Home,
  HomeContact,
  Register,
  RecoverPassword,
  ResetPassword,
  Page,
  Profile,
  ProfileEdit,
  Components,
  ProjectEdit,
  ProjectIndex,
  SlideIndex,
  SlideNew,
  SlideShow,
  SlideEdit,
  QualityExport,
  QualityNew,
  QualityShow,
  QualitySuccess,
  DeliveryRequestEdit,
  DeliveryRequestShow,
  DeliveryRequestIndex,
  DeliveryRequestMap,
  DeliveryOrderNew,
  DeliveryOrderSuccessForm
} from "../screens";

const routes = (
  <Switch>
    <LoggedLayout exact path="/home" component={Home} />
    <LoggedLayout exact path="/profile" component={Profile} />
    <LoggedLayout exact path="/profile/edit" component={ProfileEdit} />
    <LoggedLayout exact path="/slides" component={SlideIndex} />
    <LoggedLayout exact path="/slides/new" component={SlideNew} />
    <LoggedLayout exact path="/slides/:id" component={SlideShow} />
    <LoggedLayout exact path="/slides/:id/edit" component={SlideEdit} />
    <NonLoggedLayout exact path="/login" component={Login} />
    <NonLoggedLayout exact path="/register" component={Register} />
    <NonLoggedLayout exact path="/reset_password" component={ResetPassword} />
    <NonLoggedLayout
      exact
      path="/recover_password"
      component={RecoverPassword}
    />
    <TwistLayout exact path="/projects" component={ProjectIndex} />
    <TwistLayoutContainer
      exact
      path="/project/:id/quality"
      component={QualityNew}
    />
    <TwistLayout
      exact
      path="/delivery_requests/:id/edit"
      component={DeliveryRequestEdit}
    />
    <TwistLayout
      exact
      path="/delivery_requests/:id"
      component={DeliveryRequestShow}
    />
    <TwistLayout
      exact
      path="/delivery_requests_list"
      component={DeliveryRequestIndex}
    />
    <TwistLayout
      exact
      path="/map/delivery_requests"
      component={DeliveryRequestMap}
    />
    <TwistLayout
      exact
      path="/projects/:projectId/delivery_order_form/:deliveryOrderId"
      component={DeliveryOrderNew}
    />
    <PublicLayout
      exact
      path="/success_delivery_order_form"
      component={DeliveryOrderSuccessForm}
    />

    <TwistLayout exact path="/project/:id/edit" component={ProjectEdit} />
    <TwistLayout exact path="/qualities/export" component={QualityExport} />
    <PublicLayout exact path="/qualities/success" component={QualitySuccess} />
    <TwistLayoutContainer exact path="/qualities/:id" component={QualityShow} />
    <PublicLayout exact path="/" component={Home} />
    <PublicLayout exact path="/contact" component={HomeContact} />
    <PublicLayout exact path="/components" component={Components} />
    <PublicLayout path="/:page" component={Page} />
  </Switch>
);

export default routes;
