import React from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { Container, NavDropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import qs from "qs";
import jwtDecode from "jwt-decode";
import { NavbarTop, NavbarLogged, Footer } from "../components";
import { requestSignOut } from "../actions/auth";

export const LoggedLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("jwt") ? (
          <>
            <NavbarLogged>
              <NavDropdown.Item as={Link} to="/slides">
                Slides
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => dispatch(requestSignOut())}>
                Cerrar Sesión
              </NavDropdown.Item>
            </NavbarLogged>
            <Container fluid>
              <Component {...props} />
            </Container>
            <Footer />
          </>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export const NonLoggedLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("jwt") ? (
        <Redirect to={{ pathname: "/home", state: { from: props.location } }} />
      ) : (
        <>
          <NavbarTop />
          <Container fluid>
            <Component {...props} />
          </Container>
          <Footer />
        </>
      )
    }
  />
);

export const PublicLayout = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Container fluid>
          <Component {...props} />
        </Container>
      )}
    />
  );
};

export const TwistLayout = ({ component: Component, ...rest }) => {
  // const { fjwt } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  return (
    <Route
      {...rest}
      render={props => {
        const { fjwt, jwt } = qs.parse(props.location.search, {
          ignoreQueryPrefix: true
        });
        localStorage.setItem("fjwt", fjwt);
        const { language } = jwtDecode(jwt);
        localStorage.setItem("language", language);

        return fjwt ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};

export const TwistLayoutContainer = ({ component: Component, ...rest }) => {
  // const { fjwt } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
  return (
    <Route
      {...rest}
      render={props => {
        const { fjwt, jwt } = qs.parse(props.location.search, {
          ignoreQueryPrefix: true
        });
        localStorage.setItem("fjwt", fjwt);
        const { language } = jwtDecode(jwt);
        localStorage.setItem("language", language);

        return fjwt ? (
          <Container fluid>
            <Component {...props} />
          </Container>
        ) : (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        );
      }}
    />
  );
};
