import React from 'react';
import PropTypes from 'prop-types';
import { Form, ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HelpCircle } from 'react-feather';

const FormikCheckBox = ({
  label,
  margin,
  field,
  toolbarVariant,
  direction,
  tooltipText,
  delayShow,
  delayHide,
  ...props
}) => (
  <Form.Group className={`d-flex align-items-center ${margin}`}>
    <Form.Check {...field} custom type="checkbox" checked={field.value} label={label} id={field.name} {...props} />
    {tooltipText && (
      <ButtonToolbar className="ml-2">
        <OverlayTrigger
          key={direction}
          placement={direction}
          delay={{ show: delayShow, hide: delayHide }}
          overlay={<Tooltip>{tooltipText}</Tooltip>}
        >
          <HelpCircle />
        </OverlayTrigger>
      </ButtonToolbar>
    )}
  </Form.Group>
);
FormikCheckBox.propTypes = {
  direction: PropTypes.string,
  tooltipText: PropTypes.string,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
  toolbarVariant: PropTypes.string
};

FormikCheckBox.defaultProps = {
  direction: 'top',
  delayShow: 250,
  tooltipText: '',
  delayHide: 0,
  toolbarVariant: ''
};
export default FormikCheckBox;
