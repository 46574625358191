import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import snakeCaseKeys from "snakecase-keys";
import jwtDecode from "jwt-decode";
import qs from "qs";
import QualityForm from "./QualityForm";
import { createQualityRequest } from "../../requests/qualities";
import { sendAlert } from "../../actions/utils";
import { formatMessage } from "../../i18n/language";
import { DefaultModal } from "../../components";

const QualityNew = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [onRequest, setOnRequest] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [tempFormQuality, setTempFormQuality] = useState(null);

  const modalBody = "¿Estás seguro que quieres realizar esta acción?";

  const {
    match: {
      params: { id: projectId }
    },
    location: { search }
  } = props;

  const { jwt, projectName } = qs.parse(search, {
    ignoreQueryPrefix: true
  });

  const [quality] = useState({
    boxes: "",
    unitsPerBox: "",
    totalUnits: "",
    notes: "",
    theme: "",
    userName: "",
    userLegalId: "",
    state: "released",
    projectId
  });

  const { user } = jwtDecode(jwt);

  const handleSuccess = () => {
    setOnRequest(false);
    dispatch(
      sendAlert({
        kind: "success",
        message: formatMessage({ id: "project.qualities.creationSuccess" })
      })
    );
    history.push("/qualities/success");
  };

  const handleModalClose = () => {
    setModalShow(false);
  };

  const handleModalConfirm = () => {
    setOnRequest(true);
    createQualityRequest({
      dispatch,
      params: snakeCaseKeys(tempFormQuality),
      formData: true,
      successCallback: handleSuccess
    });
  };

  const handleCreateRequest = formQuality => {
    let modalTemp = "";
    if (formQuality.quality.state === "released") {
      modalTemp = "LIBERAR PEDIDO";
    } else {
      modalTemp = "RECHAZAR PEDIDO";
    }
    setModalTitle(modalTemp);
    setModalShow(true);
    setTempFormQuality(formQuality);
  };

  return (
    <>
      <Row className="m-t-50">
        <Col md={{ span: 4, offset: 4 }}>
          <h3>
            {" "}
            #{projectId} - {projectName}
          </h3>
          <QualityForm
            quality={quality}
            user={user}
            action="new"
            onRequest={onRequest}
            formRequest={handleCreateRequest}
          />
        </Col>
      </Row>
      <DefaultModal
        title={modalTitle}
        body={modalBody}
        show={modalShow}
        handleClose={handleModalClose}
        handleConfirm={handleModalConfirm}
        titleBtnClose="Cancelar"
        titleBtnSave="Confirmar"
      />
    </>
  );
};

export default QualityNew;
