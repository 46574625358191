import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { LinkBtn, FormikInput } from '../../components';
import { requestSignIn } from '../../actions/auth';

const Login = props => {
  const { onHide, isValid, submitVariant, errors, touched } = props;
  return (
    <Row className="justify-content-center align-items-center full-height">
      <Col md={4}>
        <h4>INICIAR SESIÓN</h4>
        <Form>
          <Field name="email">
            {({ field }) => (
              <FormikInput
                {...field}
                label="E-mail"
                placeholder="Email"
                inputType="email"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormikInput
                {...field}
                inputType="password"
                label="Contraseña"
                placeholder="Contraseña"
                error={errors[field.name]}
                touched={touched[field.name]}
              />
            )}
          </Field>
          <Button
            variant={submitVariant}
            size="lg"
            className="mt-4"
            block
            type="submit"
            disabled={!isValid}
            onClick={onHide}
          >
            INICIAR SESIÓN
          </Button>
        </Form>
        <LinkBtn to="/recover_password">
          ¿Olvidaste tu cuenta?
        </LinkBtn>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { ongoingRequest, signedIn } = state.auth;
  return {
    ongoingRequest,
    signedIn
  };
};

const initialValues = {
  email: '',
  password: ''
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('El email que ingresaste no es válido')
    .required('Este campo es obligatorio'),
  password: Yup.string().required('Este campo es obligatorio')
});

const handleSubmit = (values, { props }) => {
  const { dispatch } = props;
  dispatch(
    requestSignIn({
      user: {
        email: values.email,
        password: values.password
      }
    })
  );
};

export default withRouter(
  connect(mapStateToProps)(
    withFormik({
      mapPropsToValues: () => initialValues,
      validationSchema,
      handleSubmit
    })(Login)
  )
);
