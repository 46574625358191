import React from "react";
import { MainLogo } from "../../components";

const DeliveryOrderSuccessForm = () => {
  return (
    <div
      className="containerSpinnerLoad"
      style={{
        position: "absolute",
        flexDirection: "column",
        height: "100%",
        width: "98%",
        textAlign: "center"
      }}
    >
      <MainLogo />
      <h3
        style={{
          padding: "30px",
          fontWeight: "bold",
          margin: "5px 0",
          color: "rgba(255,136,17,1)"
        }}
      >
        Formulario enviado con éxito
      </h3>
      <p style={{ color: "rgba(255,136,17,1)" }}> </p>
    </div>
  );
};

export default DeliveryOrderSuccessForm;
