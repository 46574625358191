import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CustomTooltip = ({ direction, text, children, delayShow, delayHide }) => (
  <OverlayTrigger
    key={direction}
    placement={direction}
    delay={{ show: delayShow, hide: delayHide }}
    overlay={<Tooltip>{text}</Tooltip>}
  >
    {children}
  </OverlayTrigger>
);

CustomTooltip.propTypes = {
  direction: PropTypes.string,
  text: PropTypes.node.isRequired,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number
};

CustomTooltip.defaultProps = {
  direction: 'top',
  delayShow: 250,
  delayHide: 0
};

export default CustomTooltip;
