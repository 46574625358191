import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import camelCaseRecursive from "camelcase-keys-recursive";
import { Row, Col, Image, Button, Container } from "react-bootstrap";
import { LinkBtn } from "../../components";
import { showQualityRequest } from "../../requests/qualities";
import { openInNewTab } from "../../services/utils";

const ImagePreview = props => {
  const { dzf } = props;

  return (
    <Col md={3} className="m-b-20">
      <Button
        variant="link"
        onClick={() => {
          openInNewTab(dzf.fileInfo.fileUrl);
        }}
      >
        <Image src={dzf.fileInfo.fileUrl} width="100%" height="130px" />
      </Button>
    </Col>
  );
};

const QualityShow = props => {
  const [quality, setQuality] = useState({
    projectExternalId: "",
    dropZoneFiles: []
  });
  const dispatch = useDispatch();
  const {
    match: {
      params: { id: qualityId }
    },
    location: { search }
  } = props;

  const handleSuccessShow = response => {
    setQuality(camelCaseRecursive(response.data));
  };

  const getQualityData = () => {
    showQualityRequest(qualityId, {
      dispatch,
      successCallback: handleSuccessShow
    });
  };

  useEffect(getQualityData, []);

  return (
    <Container fluid>
      <Row>
        <Col md={{ span: 1, offset: 11 }}>
          <LinkBtn to={`/project/${quality.projectExternalId}/edit${search}`}>
            Volver
          </LinkBtn>
        </Col>
      </Row>
      <Row>
        {quality.dropZoneFiles.map(dzf => {
          return (
            <ImagePreview
              key={`nn-image-previem-quality-show-${dzf.id}`}
              dzf={dzf}
            />
          );
        })}
      </Row>
    </Container>
  );
};

export default QualityShow;
