import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import downloadFile from "../../services/utils";
import { indexQualitiesRequest } from "../../requests/qualities";

const styleButton = {
  borderColor: "#eea236",
  height: 32,
  fontSize: 14,
  fontFamily: "'Open Sans', sans-serif !important"
};

const QualityExport = () => {
  const dispatch = useDispatch();

  const handleSuccessDownload = response => {
    downloadFile(response);
  };

  const handleDownload = () => {
    indexQualitiesRequest({
      dispatch,
      params: { sort_column: "id", sort_direction: "desc" },
      format: "xlsx",
      successCallback: handleSuccessDownload
    });
  };

  return (
    <Button
      size="sm"
      style={styleButton}
      variant="primary"
      block
      to="/slides/new"
      onClick={handleDownload}
    >
      <FontAwesomeIcon
        icon={faDownload}
        style={{ marginInline: 10, width: 13 }}
      />
      Registros Calidad
    </Button>
  );
};

export default QualityExport;
