import pinOne from './1-pin.svg';
import pinFocusOne from './1-focus.svg';
import checkOne from './1-check.svg';

import pinTwo from './2-pin.svg';
import pinFocusTwo from './2-focus.svg';
import checkTwo from './2-check.svg';

import pinThree from './3-pin.svg';
import pinFocusThree from './3-focus.svg';
import checkThree from './3-check.svg';

import pinFour from './4-pin.svg';
import pinFocusFour from './4-focus.svg';
import checkFour from './4-check.svg';

import pinFive from './5-pin.svg';
import pinFocusFive from './5-focus.svg';
import checkFive from './5-check.svg';

import pinSix from './6-pin.svg';
import pinFocusSix from './6-focus.svg';
import checkSix from './6-check.svg';

import pinSeven from './7-pin.svg';
import pinFocusSeven from './7-focus.svg';
import checkSeven from './7-check.svg';

const icons = [
  {
    pin: pinOne,
    pinFocus: pinFocusOne,
    check: checkOne
  },
  {
    pin: pinTwo,
    pinFocus: pinFocusTwo,
    check: checkTwo
  },
  {
    pin: pinThree,
    pinFocus: pinFocusThree,
    check: checkThree
  },
  {
    pin: pinFour,
    pinFocus: pinFocusFour,
    check: checkFour
  },
  {
    pin: pinFive,
    pinFocus: pinFocusFive,
    check: checkFive
  },
  {
    pin: pinSix,
    pinFocus: pinFocusSix,
    check: checkSix
  },
  {
    pin: pinSeven,
    pinFocus: pinFocusSeven,
    check: checkSeven
  }
];

export default icons;
