import React, { useState } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper, Polyline } from 'google-maps-react';
import { v4 as uuidv4 } from 'uuid';

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '500px'
};

export const MapContainer = ({ center, markers, isMarkerShown, zoom, google, isFocus, lineCoords = [] }) => {
  const centerMap = center || markers[0];
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [activeCustomMarker, setActiveCustomMarker] = useState(null);

  const onMarkerClick = (p, mark, marker) => {
    setActiveMarker(mark);
    setActiveCustomMarker(marker);
    setSelectedMarker(true);
  };

  const renderMarkers = () => {
    return markers.map(marker => {
      const status = marker.status || 1;
      return (
        <Marker
          key={`pin-${uuidv4()}`}
          position={{
            lat: marker.lat,
            lng: marker.lng
          }}
          onClick={(p, mark) => onMarkerClick(p, mark, marker)}
          icon={{
            url: marker.icon,
            scaledSize: new google.maps.Size(40, 40)
          }}
          opacity={status === 1 ? 1 : 0.9}
        />
      );
    });
  };

  const renderPolyLines = () => {
    return lineCoords.map(coords => (
      <Polyline key={uuidv4()} path={coords.coords} strokeColor={coords.strokeColor} strokeOpacity={0.7} strokeWeight={3} />
    ));
  };

  return (
    <Map
      onClick={() => {
        setSelectedMarker(null);
        setActiveMarker(null);
        setActiveCustomMarker(null);
      }}
      google={google}
      center={centerMap}
      initialCenter={centerMap}
      style={containerStyle}
      zoom={isFocus ? 15 : zoom}
    >
      {isMarkerShown && renderMarkers()}
      {activeCustomMarker && activeMarker && (
        <InfoWindow visible={selectedMarker} marker={activeMarker}>
          <>
          {activeCustomMarker.info}&nbsp;
          </>
        </InfoWindow>
      )}
      {renderPolyLines()}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API
})(MapContainer);
