import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Spinner } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import qs from "qs";
import jwtDecode from "jwt-decode";

import { formatMessage } from "../../i18n/language";
import { indexProjectsRequest } from "../../requests/projects";

class ProjectIndex extends Component {
  state = {
    loading: false,
    projects: [],
    user: {
      id: "",
      email: "",
      rut: "",
      name: "",
      last_name: ""
    }
  };

  componentDidMount = () => {
    const { location } = this.props;
    const { jwt } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { dispatch } = this.props;
    const { user } = jwtDecode(jwt);
    this.setState({ loading: true, user });
    indexProjectsRequest({
      dispatch,
      successCallback: this.handleSuccessCallback
    });
  };

  handleSuccessCallback = response => {
    const { data } = response;
    this.setState({ projects: data, loading: false });
  };

  render() {
    const { loading, projects, user } = this.state;
    return (
      <>
        <Row className="mt-3">
          <Col>
            <h2>{formatMessage({ id: "project.index.title" })}</h2>
            <h3>{formatMessage({ id: "test" })}</h3>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Datos Usuario</h3>
            <p>ID: {user.id}</p>
            <p>RUT: {user.rut}</p>
            <p>E-mail: {user.email}</p>
            <p>Name: {user.name}</p>
            <p>Last name: {user.last_name}</p>
          </Col>
          <Col>
            <h3>Últimos proyectos</h3>
            {loading && (
              <div
                className="containerSpinnerLoad"
                style={{ position: "absolute", height: "100%" }}
              >
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {projects.map(p => (
              <Row key={p.id} className="mt-3">
                <Col>
                  <p>
                    {p.id} - {p.name}
                  </p>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </>
    );
  }
}

export default withRouter(connect()(ProjectIndex));
