import AwesomeDebouncePromise from "awesome-debounce-promise";
import ApiService from "../services/apiService";

export const createQualityRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = "";
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request("post", `/qualities${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const indexQualitiesRequest = ({
  dispatch,
  format,
  params = {},
  failureCallback,
  successCallback
}) => {
  let formatt = "";
  if (format) {
    formatt = `.${format}`;
  }
  ApiService.request("get", `/qualities${formatt}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });
};

export const showQualityRequest = (
  id,
  { dispatch, params = {}, failureCallback, successCallback }
) =>
  ApiService.request("get", `/qualities/${id}`, {
    dispatch,
    params,
    failureCallback,
    successCallback
  });

export const noop = AwesomeDebouncePromise(() => {}, 300);
