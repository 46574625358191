import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import snakeCaseKeys from "snakecase-keys";
import camelCaseRecursive from "camelcase-keys-recursive";
import qs from "qs";
import jwtDecode from "jwt-decode";
import DeliveryOrderForm from "./DeliveryOrderForm";
import {
  updateDeliveryOrder,
  findOrCreateDeliveryOrderRequest
} from "../../requests/deliveryOrders";
import { genericErrorMessage } from "../../services/apiService";
import { sendAlert } from "../../actions/utils";

const DeliveryOrderNew = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [onRequest, setOnRequest] = useState(false);
  const [deliveryOrder, setDeliveryOrder] = useState(false);
  const {
    match: {
      params: { deliveryOrderId, projectId }
    },
    location: { search }
  } = props;

  let jwt = "";
  let fjwt = "";
  let rut = "";

  if (search) {
    const { jwt: auxJwt, fjwt: auxfJwt } = qs.parse(search, {
      ignoreQueryPrefix: true
    });
    jwt = auxJwt;
    fjwt = auxfJwt;
    if (jwt) {
      const { user } = jwtDecode(jwt);
      rut = user?.rut || "";
    }
  }

  const handleCreateRequest = formDelivery => {
    setOnRequest(true);
    updateDeliveryOrder(projectId, deliveryOrderId, {
      dispatch,
      params: snakeCaseKeys(formDelivery),
      formData: true,
      successCallback: () => {
        setOnRequest(false);
        if (search && !!rut) {
          history.push(
            `/delivery_requests_list?jwt=${encodeURIComponent(
              jwt
            )}&fjwt=${encodeURIComponent(fjwt)}`
          );
        } else {
          history.push(`/success_delivery_order_form`);
        }

        dispatch(
          sendAlert({
            kind: "success",
            message: "Formulario ingresado con éxito"
          })
        );
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  const redirectIndex = () => {
    history.push(
      `/delivery_requests_list?jwt=${encodeURIComponent(
        jwt
      )}&fjwt=${encodeURIComponent(fjwt)}`
    );
  };

  const handleFindOrCreateDeliveryOrderRequest = () => {
    setOnRequest(true);
    findOrCreateDeliveryOrderRequest(projectId, deliveryOrderId, {
      dispatch,
      formData: true,
      successCallback: response => {
        setOnRequest(false);
        setDeliveryOrder({ ...camelCaseRecursive(response.data) });
      },
      failureCallback: (error, dis) => {
        genericErrorMessage(error, dis);
        setOnRequest(false);
      }
    });
  };

  useEffect(() => {
    handleFindOrCreateDeliveryOrderRequest();
  }, []);

  return (
    deliveryOrder && (
      <>
        <Row style={{ marginInline: 0 }}>
          <Col>
            <DeliveryOrderForm
              action="new"
              onRequest={onRequest}
              formRequest={handleCreateRequest}
              deliveryOrderId={deliveryOrder.id}
              rut={search ? rut : ""}
              redirect={() => redirectIndex()}
            />
          </Col>
        </Row>
      </>
    )
  );
};

export default DeliveryOrderNew;
