import React from "react";
import { Image } from "react-bootstrap";
import logo from "./twist-logo.svg";

const MainLogo = () => (
  <Image
    src={logo}
    alt="Logo"
    style={{ width: "17%", marginBottom: "20px" }}
    className="d-inline-block align-top"
  />
);

export default MainLogo;
