import React, { Component } from 'react';
import { Edit } from 'react-feather';
import { connect } from 'react-redux';
import snakeCaseKeys from 'snakecase-keys';

import { LinkBtn } from '../../components';
import ProfileForm from './ProfileForm';
import {
  showUserRequest,
  updateUserRequest,
  deleteDocumentRequest
} from '../../requests/user';
import { sendAlert } from '../../actions/utils';

class ProfileEdit extends Component {
  state = {
    user: {
      persistedAvatar: [],
      currentAvatar: []
    },
    documentCode: Date.now(),
    maxAvatarSize: 20971520
  };

  componentDidMount() {
    const { dispatch } = this.props;
    showUserRequest({
      dispatch,
      successCallback: response => this.handleSuccessShow(response)
    });
  }

  handleSuccessShow = response => {
    const { user: oldUser } = this.state;
    const user = response.data;
    this.setState({
      user: {
        ...oldUser,
        persistedAvatar: user.avatar
      }
    });
  };

  updateUserAvatar = documents => {
    const { user } = this.state;
    const updatedState = {
      ...this.state,
      user: {
        ...user,
        currentAvatar: [...documents],
        persistedAvatar: [...user.persistedAvatar]
      }
    };
    this.setState(updatedState);
  };

  handleUpdateRequest = user => {
    const { dispatch } = this.props;
    updateUserRequest({
      dispatch,
      params: snakeCaseKeys(user),
      formData: true,
      successCallback: response => this.handleSuccessUpdate(response)
    });
  };

  handleSuccessUpdate = () => {
    const { dispatch, history } = this.props;
    dispatch(
      sendAlert({
        kind: 'success',
        message: 'Perfil actualizado correctamente'
      })
    );
    history.push('/profile');
  };

  destroyDocumentRequest = document => {
    const { dispatch } = this.props;
    deleteDocumentRequest({
      dispatch,
      params: snakeCaseKeys(document),
      formData: true,
      successCallback: response => this.handleSuccessDestroyDocument(response)
    });
  };

  handleSuccessDestroyDocument = response => {
    const documentInfo = JSON.parse(response.config.data);
    const { user } = this.state;
    const { persistedAvatar } = user;
    const updatedPersistedAvatar = persistedAvatar.filter(
      avatar => avatar.id !== documentInfo.document_id
    );
    const updatedState = {
      ...this.state,
      user: {
        ...user,
        persistedAvatar: updatedPersistedAvatar
      }
    };
    this.setState(updatedState);
  };

  render() {
    const { user, documentCode, maxAvatarSize } = this.state;
    return (
      <div>
        <h1>EDITAR PERFIL</h1>
        <ProfileForm
          user={user}
          documentCode={documentCode}
          updateUserAvatar={this.updateUserAvatar}
          maxAvatarSize={maxAvatarSize}
          formRequest={this.handleUpdateRequest}
          destroyDocumentRequest={this.destroyDocumentRequest}
        />
        <LinkBtn to="/" variant="primary">
          <Edit />
          Home
        </LinkBtn>
      </div>
    );
  }
}

export default connect()(ProfileEdit);
